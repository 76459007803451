import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { Spinner, Card, Row, Col, Table, DropdownButton, Dropdown } from 'react-bootstrap';
import { getDatabase, ref, set } from "firebase/database"; // Realtime Database Funktionen
import { utcToZonedTime } from "date-fns-tz";

const Anwesheitclickday = ({ currentEvent, day }) => {
  const { currentUser, isAdmin } = useAuth();
  const [FID, setFID] = useState("");
  const navigate = useNavigate();
  const [data, SetMyData] = useState({}); // Initialisiere mit einem leeren Objekt
  const [userid, SetMyUserID] = useState({});
  
  const timeZone = "Europe/Berlin";
  const millesek = utcToZonedTime(day, timeZone).setHours(1, 0, 0, 0);
  const [spesen, setSpesen] = useState(false); // State für Spesenänderung

  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!isAdmin) {
      navigate("/Dashbord", { replace: true });
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (currentEvent) {
      const eventKey = Object.keys(currentEvent)[0];
      SetMyUserID(eventKey);
      SetMyData(currentEvent[eventKey]);
      setSpesen(currentEvent[eventKey]?.spesen || false); // Initialer Wert für Spesen
    }
  }, [currentEvent]);


  useEffect(() => {
    const setVh = () => {
      // 1vh entspricht 1% der Fensterhöhe
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
  
    setVh();
    window.addEventListener('resize', setVh);
    return () => window.removeEventListener('resize', setVh);
  }, []);

  const handleSpesenChange = async (value) => {
    setSpesen(value); // Setze neuen Wert für Spesen
    if (data.Fahrzeug?.ID) {
      try {
        const db = getDatabase();
        const eventRef = ref(db, "dailybusiness/" + FID + "/" + millesek + "/anwesenheit/" + userid);
        
        // Spesen in der Realtime Database aktualisieren
        await set(eventRef, {
          ...data,  // Behalte bestehende Daten bei
          spesen: value,
        });
        
        console.log("Spesen erfolgreich aktualisiert");
      } catch (error) {
        console.error("Fehler beim Aktualisieren der Spesen:", error);
      }
    }
  };

  if (isEmpty(data)) {
    return <Spinner animation="border" variant="primary" />;
  } else {
    console.log(data);
  }

  // Berechnung der Zeitdifferenz für "Aufenthalt Feierabendzone"
  let formattedDuration = "Nicht verfügbar";
  if (data.end && data.places && data.places["Feierabend Zone"]) {
    const feierabendZoneEvents = data.places["Feierabend Zone"];
    const enterTimestamps = Object.keys(feierabendZoneEvents)
      .filter(timestamp => feierabendZoneEvents[timestamp] === "Enter")
      .map(timestamp => parseInt(timestamp, 10));
    if (enterTimestamps.length > 0) {
      const lastEnterFeierabend = Math.max(...enterTimestamps);
      const durationMs = data.end - lastEnterFeierabend;
      if (durationMs >= 0) {
        const totalMinutes = Math.floor(durationMs / 60000);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        formattedDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
      }
    }
  }

  return (
    <>
      <h1 className="mb-4">Details über den Tag</h1>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Fahrzeug Information</Card.Title>
          <Row>
            <Col xs={12} md={6}><strong>Fahrzeug ID:</strong> {data.Fahrzeug?.ID || "Nicht verfügbar"}</Col>
            <Col xs={12} md={6}><strong>Kennzeichen:</strong> {data.Fahrzeug?.Kennzeichen || "Nicht verfügbar"}</Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Spesen</Card.Title>
          <Row>
            <Col xs={12} md={6}>
              <DropdownButton
                id="dropdown-spesen"
                title={spesen ? "Ja" : "Nein"}
                onSelect={(selectedValue) => handleSpesenChange(selectedValue === "Ja")}
              >
                <Dropdown.Item eventKey="Ja">Ja</Dropdown.Item>
                <Dropdown.Item eventKey="Nein">Nein</Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Paletten</Card.Title>
          <Row>
            <Col xs={12} md={6}><strong>Geladene Paletten (FP):</strong> {data.paletten?.FP?.geladen || "Nicht verfügbar"}</Col>
            <Col xs={12} md={6}><strong>Entladene Paletten (FP):</strong> {data.paletten?.FP?.entladen || "Nicht verfügbar"}</Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={6}><strong>Geladene Gitterboxen (GP):</strong> {data.paletten?.GP?.geladen || "Nicht verfügbar"}</Col>
            <Col xs={12} md={6}><strong>Entladene Gitterboxen (GP):</strong> {data.paletten?.GP?.entladen || "Nicht verfügbar"}</Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Bewegung</Card.Title>
          <Row>
            <Col xs={12} md={6}><strong>Bewegung:</strong> {data.movement?.movement ? 'Ja' : 'Nein'}</Col>
            <Col xs={12} md={6}><strong>Sensor Status:</strong> {data.movement?.sensor || "Nicht verfügbar"}</Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Tour</Card.Title>
          <div><strong>Tour:</strong> {data.Tour || "Nicht verfügbar"}</div>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Orte</Card.Title>
          <h5>Feierabend Zone</h5>
          {data.places?.["Feierabend Zone"] ? (
            <Table responsive bordered hover size="sm">
              <thead>
                <tr>
                  <th>Zeit</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data.places["Feierabend Zone"]).map(([time, status]) => (
                  <tr key={time}>
                    <td>{new Date(parseInt(time)).toLocaleString()}</td>
                    <td>{status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>Keine Daten für die Feierabend Zone</div>
          )}

          <h5 className="mt-3">Lade-Entlade Punkt</h5>
          {data.places?.["Lade-Entlade Punkt"] ? (
            <Table responsive bordered hover size="sm">
              <thead>
                <tr>
                  <th>Zeit</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data.places["Lade-Entlade Punkt"]).map(([time, status]) => (
                  <tr key={time}>
                    <td>{new Date(parseInt(time)).toLocaleString()}</td>
                    <td>{status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>Keine Daten für den Lade-Entlade Punkt</div>
          )}
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Sonstiges</Card.Title>
          <Row>
            <Col xs={12} md={6}><strong>SLS:</strong> {data.SLS || "Nicht verfügbar"}</Col>
            <Col xs={12} md={6}><strong>Tankstellen?</strong> {data.Tankstelle ? 'Ja' : 'Nein'}</Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={6}><strong>Spesen:</strong> {data.spesen ? 'Ja' : 'Nein'}</Col>
            <Col xs={12} md={6}><strong>Tagesabschluss:</strong> {data.tagesabschluss ? 'Ja' : 'Nein'}</Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={6}><strong>Zone Feierabend:</strong> {data.zonefeierabend ? 'Ja' : 'Nein'}</Col>
            <Col xs={12} md={6}><strong>Aufenthalt Feierabendzone:</strong> {formattedDuration}</Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Anwesheitclickday;
