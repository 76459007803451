import React, { useEffect, useState } from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import { ref, getDatabase, onValue } from "firebase/database";
import usePalettenKontoData from "../palettenkonto/usePalettenKontoData";
import moment from "moment";

const MitarbeiterDashboard = () => {
  const navigate = useNavigate();
  const { currentUser, loading, FID, UID } = useAuth();
  const [stoppsStatistik, setStoppsStatistik] = useState(null);
  const [isLoadingStopps, setIsLoadingStopps] = useState(true); // 🔄 Loader für Stopps-Daten

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    }
  }, [currentUser, navigate]);

  // 📌 Palettenkonto-Daten laden
  const { summaries, isLoading: loadingPaletten } = usePalettenKontoData(FID);

  // 📌 Stopps des Benutzers seit Jahresbeginn laden
  useEffect(() => {
    if (!FID || !UID) return;

    const db = getDatabase();
    const startOfYear = moment().startOf("year").valueOf(); // 1. Januar des aktuellen Jahres
    const today = moment().endOf("day").valueOf(); // Heute bis 23:59:59
    const stoppsRef = ref(db, `/dailybusiness/${FID}`);

    onValue(stoppsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let totalStopps = 0;
        let erfolgreich = 0;
        let vergeblich = 0;
        let abholer = 0;
        let totalDistance = 0;
        let daysWithStopps = new Set();

        // 📌 Durchlaufe alle Tage
        Object.keys(data).forEach((dateKey) => {
          if (dateKey >= startOfYear && dateKey <= today) {
            const stopps = data[dateKey]?.stopps || {};

            // 📌 Stopps nach Benutzer-ID filtern
            Object.values(stopps)
              .filter((stopp) => stopp.userid === UID)
              .forEach((stopp) => {
                totalStopps++;
                totalDistance += stopp.distance;
                daysWithStopps.add(dateKey);

                switch (stopp.art) {
                  case "erfolgreich":
                    erfolgreich++;
                    break;
                  case "vergeblich":
                    vergeblich++;
                    break;
                  case "abholer":
                    abholer++;
                    break;
                  default:
                    break;
                }
              });
          }
        });

        const totalDays = daysWithStopps.size || 1; // Keine Division durch 0

        setStoppsStatistik({
          totalStopps,
          erfolgreich,
          vergeblich,
          abholer,
          totalDistance,
          avgStopps: (totalStopps / totalDays).toFixed(2),
          avgErfolgreich: (erfolgreich / totalDays).toFixed(2),
          avgVergeblich: (vergeblich / totalDays).toFixed(2),
          avgAbholer: (abholer / totalDays).toFixed(2),
          avgDistance: (totalDistance / totalDays).toFixed(2),
        });

        setIsLoadingStopps(false); // ✅ Stopps-Daten wurden geladen
      }
    });
  }, [FID, UID]);

  // 📌 Loader für **Stopps & Palettenkonto-Daten**
  if (loading || loadingPaletten || isLoadingStopps) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
        <p>Lade Dashboard-Daten...</p>
      </div>
    );
  }

  const userSummary = summaries?.find((s) => s.name === UID);
  console.log("summaries->", summaries);

  return (
    <div className="container mt-4">
      {/* ✅ Stopps-Statistik */}
      {stoppsStatistik && (
        <Row className="mt-4">
          <Col md={6}>
            <Card className="p-3">
              <h5 className="text-center">
                Stopps Übersicht (Jahresanfang - Heute)
              </h5>
              <p>
                Gesamt Stopps: <strong>{stoppsStatistik.totalStopps}</strong>
              </p>
              <p>
                Erfolgreich: <strong>{stoppsStatistik.erfolgreich}</strong>
              </p>
              <p>
                Vergeblich: <strong>{stoppsStatistik.vergeblich}</strong>
              </p>
              <p>
                Abholer: <strong>{stoppsStatistik.abholer}</strong>
              </p>
              <p>
                Gesamtdistanz:{" "}
                <strong>
                  {(stoppsStatistik.totalDistance / 1000).toFixed(2)} km
                </strong>
              </p>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="p-3">
              <h5 className="text-center">Durchschnitt pro Tag</h5>
              <p>
                ⏳ Stopps: <strong>{stoppsStatistik.avgStopps}</strong>
              </p>
              <p>
                ✅ Erfolgreich:{" "}
                <strong>{stoppsStatistik.avgErfolgreich}</strong>
              </p>
              <p>
                ❌ Vergeblich: <strong>{stoppsStatistik.avgVergeblich}</strong>
              </p>
              <p>
                📦 Abholer: <strong>{stoppsStatistik.avgAbholer}</strong>
              </p>
              <p>
                📏 Distanz:{" "}
                <strong>
                  {(stoppsStatistik.avgDistance / 1000).toFixed(2)} km
                </strong>
              </p>
            </Card>
          </Col>
        </Row>
      )}

      {/* ✅ Palettenkonto Differenzen */}
      <Row className="mt-4">
        <Col md={12}>
          <Card className="p-3">
            <h5 className="text-center">Palettenkonto Differenzen</h5>
            <div className="text-center">
              <h3
                style={{
                  color:
                    userSummary && userSummary.diffFP > 0 ? "red" : "inherit",
                }}
              >
                FP Differenz:{" "}
                {userSummary
                  ? userSummary.diffFP <= 0
                    ? "OK"
                    : userSummary.diffFP
                  : "N/A"}
              </h3>

              <h3
                style={{
                  color:
                    userSummary && userSummary.diffGP > 0 ? "red" : "inherit",
                }}
              >
                GP Differenz:{" "}
                {userSummary
                  ? userSummary.diffGP <= 0
                    ? "OK"
                    : userSummary.diffGP
                  : "N/A"}
              </h3>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MitarbeiterDashboard;
