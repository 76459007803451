import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGasPump,faUsers,faTruck,faPallet, faMapMarkerAlt , faRoad ,faEuroSign,faCalendar,
  faChartLine,faPersonChalkboard, faMap ,faWaveSquare,faChartBar,faCocktail,faPlaneDeparture, faDashboard, faFileUpload} from '@fortawesome/free-solid-svg-icons'



export const MenuItems = [
   
    {
      for: "Verwaltung",
      admin: true,
      title: ' Mitarbeiter',
      path: '/mitarbeiter',
      cName: 'dropdown-link',
      fas: <FontAwesomeIcon icon={faUsers} />
    },
    {
      for: "Verwaltung",
      admin: true,
      title: ' Fahrzeuge',
      path: '/fahrzeuge',
      cName: 'dropdown-link',
      fas: <FontAwesomeIcon icon={faTruck} />
    },
    {
      for: "Verwaltung",
      admin: true,
      title: ' Touren',
      path: '/touren',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faRoad} />
    },
    {
      for: "Verwaltung",
      admin: true,
      title: ' Vergütung',
      path: '/verguetung',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faEuroSign} />
    },
    {
      for: "Verwaltung",
      admin: false,
      title: ' Urlaubskalender',
      path: '/kalender',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faCalendar} />
    },
    {
      for: "Verwaltung",
      admin: false,
      title: ' Dashboard',
      path: '/mitarbeiterDashboard',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faDashboard} />
    },    
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' Dashboard',
      path: '/dashboard',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faDashboard} />
    },    
    {
      for: "Verwaltung",
      admin: false,
      divider: true   
    },   
    {
      for: "Einstellung",
      admin: true,
      title: ' Urlaubsdaten',
      path: '/urlaubsdaten',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faCalendar} />
    },
    {
      for: "Einstellung",
      admin: true,
      title: ' POI',
      path: '/POIManager',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faMapMarkerAlt} />
    },
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' Stopps',
      path: '/stoppsauswertung',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faChartLine} />
    },   
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' Live',
      path: '/live',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faWaveSquare} />
    },   
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' Live Map',
      path: '/liveMap',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faMap} />
    },   
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' Anwesenheit',
      path: '/anwesenheit',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faPersonChalkboard} />
    },   
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' Tankungen',
      path: '/tankungen',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faGasPump} />
    },   
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' Diagramme',
      path: '/diagramme',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faChartBar} />
    },
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' Palettenkonto',
      path: '/palettenkonto',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faPallet} />
    },
    /*{
      for: "erweiterte Funktionen",
      admin: false,
      divider: true   
    },      
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' Hochladen der Lohnabrechnungen',
      path: '/lohnabrechnungen',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faEuroSign} />
    }, */     
    {
      for: "Verwaltung",
      admin: false,
      title: ' Spesen Anzeigen',
      path: '/spesen',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faCocktail} />
    },      
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' LKW Kostenübersicht',
      path: '/truckCostOverview',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faEuroSign} />
    },
    {
      for: "erweiterte Funktionen",
      admin: true,
      title: ' Datei Upload',
      path: '/fileUpload',
      cName: 'dropdown-link',
      fas:  <FontAwesomeIcon icon={faFileUpload} />
    }


  ];
