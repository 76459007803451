import React from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";

function GeneralInfoTab({ data, setFormData, IsnewUser, passwort, setPasswort }) {  
  return (
    <Row className="mb-3">
      <Form.Group as={Col} md="6" controlId="validationCustom01">
        <Form.Label>Vor & Nachname</Form.Label>
        <Form.Control
          required
          type="text"
          autoComplete="name"
          placeholder="Vor & Nachname"
          value={data.name || ""}
          onChange={(event) =>
            setFormData({ ...data, name: event.target.value })
          }
        />
        <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="6" controlId="validationCustomUsername">
        <Form.Label>Email Adresse</Form.Label>
        <InputGroup hasValidation>
          <InputGroup.Text>@</InputGroup.Text>
          <Form.Control
            type="email"
            placeholder="Email Adresse"
            autoComplete="email"
            required
            value={data.email || ""}
            onChange={(event) =>
              setFormData({ ...data, email: event.target.value })
            }
          />
          <Form.Control.Feedback type="invalid">
            Bitte Email Adresse eingeben.
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      {IsnewUser && (
        <Form.Group as={Col} md="6" controlId="validationCustomPasswort">
          <Form.Label>Passwort</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="password"
              placeholder="Passwort"
              autoComplete="new-password"
              required
              minLength={6}
              value={passwort}
              onChange={(event) => setPasswort(event.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Bitte ein Passwort von min. 6 Zeichen eingeben.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      )}

      <Form.Group as={Col} md="6" controlId="validationCustom06">
        <Form.Label>Handynummer</Form.Label>
        <Form.Control
          type="text"
          autoComplete="tel"
          placeholder="Handynummer"
          required
          value={data.telefon || ""}
          onChange={(event) =>
            setFormData({ ...data, telefon: event.target.value })
          }
        />
        <Form.Control.Feedback type="invalid">
          Bitte Handynummer eingeben!
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom04">
          <Form.Label>Straße</Form.Label>
          <Form.Control
            type="text"
            autoComplete="street-address"
            placeholder="Straße"
            required
            value={data.strasse || ""}
            onChange={(event) =>
              setFormData({ ...data, strasse: event.target.value })
            }
          />
          <Form.Control.Feedback type="invalid">
            Bitte eine Straße eingeben.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label>Wohnort</Form.Label>
          <Form.Control
            type="text"
            autoComplete="address-line2"
            placeholder="Wohnort"
            required
            value={data.ort || ""}
            onChange={(event) =>
              setFormData({ ...data, ort: event.target.value })
            }
          />
          <Form.Control.Feedback type="invalid">
            Bitte einen Ort eingeben.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="4" controlId="validationCustom05">
          <Form.Label>PLZ</Form.Label>
          <Form.Control
            type="text"
            placeholder="PLZ"
            autoComplete="postal-code"
            required
            minLength={5}
            maxLength={5}
            pattern="[0-9]{5}"
            value={data.plz || ""}
            onChange={(event) =>
              setFormData({ ...data, plz: event.target.value })
            }
          />
          <Form.Control.Feedback type="invalid">
            Bitte eine Postleitzahl eingeben.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </Row>
  );
}

export default GeneralInfoTab;
