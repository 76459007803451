import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
// Lade die Firebase-Konfigurationsdaten aus Umgebungsvariablen
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialisiere Firebase-App
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Aktiviere App Check (ReCaptcha)
// Tipp: Lege dir den Key als ENV-Variable an, z.B. process.env.REACT_APP_RECAPTCHA_KEY
//console.log(process.env.REACT_APP_RECAPTCHA_KEY);

//const appCheck = firebase.appCheck();
//appCheck.activate(process.env.REACT_APP_RECAPTCHA_KEY, /* Optional: */ { isTokenAutoRefreshEnabled: true });

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_KEY),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});


export const auth = app.auth();

export default app;
