import React from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { SliderPicker } from "react-color";
import Select from "react-select";


function formatTime(dateObj) {
  if (!dateObj) return "";
  const hh = String(dateObj.getHours()).padStart(2, "0");
  const mm = String(dateObj.getMinutes()).padStart(2, "0");
  return `${hh}:${mm}`;
}

function parseTimeToDate(timeStr, oldDate) {
  if (!timeStr) return null;
  const [hh, mm] = timeStr.split(":").map(Number);
  const newDate = oldDate ? new Date(oldDate) : new Date();
  newDate.setHours(hh);
  newDate.setMinutes(mm);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
}





function SpecificDataTab({ 
  data, 
  setFormData, 
  options,
  AZeiten, 
  setAZeiten 
 }) {
 
  const days = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];

  const handleCheckboxChange = (index) => {
    
    setAZeiten((prev) => 
      prev.map((item, i) => {
        if (i === index) {
          return { ...item, active: !item.active };
        }
        return item;
      })
    );
  };

  const handleTimeChange = (index, field, value) => {
    // field: 'beginn' oder 'ende'
    setAZeiten((prev) =>
      prev.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value };
        }
        return item;
      })
    );
  };





  return (
    <>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationf">
          <InputGroup.Text style={{ backgroundColor: data.color }}>
            Mitarbeiter Farbe: {data.color}
          </InputGroup.Text>
          <SliderPicker
            color={data.color || ""}
            onChangeComplete={(e) =>
              setFormData({ ...data, color: e.hex })
            }
          />
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="validationr">
          <InputGroup.Text>Rollen des Mitarbeiters</InputGroup.Text>
          <Select
            isMulti
            options={options} // ✅ Hier wird options jetzt verwendet
            value={Array.isArray(data.rollen) ? data.rollen : []} // ✅ Sicherstellen, dass rollen ein Array ist
            onChange={(selectedOptions) => setFormData({ ...data, rollen: selectedOptions })}
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="3" controlId="validationisMitarbeiterAktiv">
          <Form.Check
            label="Ist der Mitarbeiter Aktiv"
            checked={data.isAktiv && !data.isDelete}
            disabled={data.isDelete}
            onChange={(e) =>
              setFormData({ ...data, isAktiv: e.target.checked })
            }
          />
        </Form.Group>

        <Form.Group as={Col} md="3" controlId="validationisGeloescht">
          <Form.Check
            label="Mitarbeiter gelöscht?"
            checked={data.isDelete || ""}
            onChange={(e) =>
              setFormData({ ...data, isDelete: e.target.checked })
            }
          />
        </Form.Group>

        <Form.Group as={Col} md="3" controlId="validationisLohnabrechnungen">
          <Form.Check
            label="Bekommt Lohnabrechnungen"
            checked={data.isLohnabrechnungen || ""}
            onChange={(e) =>
              setFormData({
                ...data,
                isLohnabrechnungen: e.target.checked,
              })
            }
          />
        </Form.Group>

        <Form.Group as={Col} md="3" controlId="validationisSpesen">
          <Form.Check
            label="Bekommt Spesen"
            checked={data.isSpesen || ""}
            onChange={(e) =>
              setFormData({ ...data, isSpesen: e.target.checked })
            }
          />
        </Form.Group>
      </Row>

      <Form.Group as={Col} md="6" controlId="validationPathLohn">
        <Form.Label>Pfad zu Lohnabrechnungen</Form.Label>
        <Form.Control
          type="text"
          disabled={!data.isLohnabrechnungen}
          placeholder="Pfad zu Lohnabrechnungen"
          value={data.pathlohn || ""}
          onChange={(event) =>
            setFormData({ ...data, pathlohn: event.target.value })
          }
        />
      </Form.Group>
      <hr />
      <h5>Arbeitszeiten (Mo–So)</h5>
      {AZeiten.map((tag, index) => (
       
        <Row key={index} className="mb-3 align-items-center">
          <Col md={3}>
            <Form.Check
              type="checkbox"
              label={days[index]}
              checked={tag.active}
              onChange={() => handleCheckboxChange(index)}
            />
          </Col>

          {/* Nur anzeigen, wenn Tag aktiviert */}
          {tag.active && (
            <>
              <Col md={3}>
                <Form.Label>Beginn</Form.Label>
                <Form.Control
                  type="time"
                  value={tag.beginn ? formatTime(tag.beginn) : ""}
                  onChange={(e) => {
                    const newDate = parseTimeToDate(e.target.value, tag.beginn);
                    handleTimeChange(index, "beginn", newDate);
                  }}
                />
              </Col>
              <Col md={3}>
                <Form.Label>Ende</Form.Label>
                <Form.Control
                  type="time"
                  value={tag.ende ? formatTime(tag.ende) : ""}
                  onChange={(e) => {
                    const newDate = parseTimeToDate(e.target.value, tag.ende);
                    handleTimeChange(index, "ende", newDate);
                  }}
                />
              </Col>
            </>
          )}
        </Row>
      ))}


    </>
  );
}

export default SpecificDataTab;
