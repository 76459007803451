import React, { useState, useEffect } from "react";
import { Card, Container, Button, Row, Spinner } from "react-bootstrap";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import { ref, get, child, getDatabase, onValue } from "firebase/database";
import BootstrapTable from "react-bootstrap-table-next";
import { addDays, subDays, format } from "date-fns";
import "bootstrap-icons/font/bootstrap-icons.css";

// 🎉 Kleines Custom-Hook, das die Bildschirm-Breite zurückgibt:
function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
}

export const Live = () => {
  const { isAdmin,FID } = useAuth();
  const navigate = useNavigate();
 // const [FID, setFID] = useState("");
  const [stoppsdata, setStoppsData] = useState([]);
  const [verguettung, setVerguettung] = useState([]);
  const [gesamtbetrag, setGesamtBetrag] = useState(0);
  const [gesamtstopps, setGesamtStopps] = useState(0);
  const [durchschnittbetrag, setDurchschnittBetrag] = useState(0);
  const [durchschnittstopps, setDurchschnittstopps] = useState(0);
  const [dayshow, setDayShow] = useState(new Date());
  const [milltoDay, setMilltoDay] = useState(
    new Date().setUTCHours(0, 0, 0, 0)
  );
  const [loading, setLoading] = useState(true);
  const [loadingVerguettung, setLoadingVerguettung] = useState(true);
  const db = getDatabase();

  // ➜ Bildschirmbreite
  const screenWidth = useWindowSize();

 /* useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser]);*/

  useEffect(() => {
    if (!isAdmin) {
      navigate("/Dashbord", { replace: true });
    }
  }, [isAdmin, navigate]);

  // ✅ Vergütung aus Firebase laden
  useEffect(() => {
    if (!FID) return;
    get(child(ref(db), `verguetung/${FID}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const verguettungList = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setVerguettung(verguettungList);
        } else {
          console.log("Keine Vergütungsdaten gefunden.");
        }
        setLoadingVerguettung(false);
      })
      .catch((error) => {
        console.error("Fehler beim Laden der Vergütung:", error);
        setLoadingVerguettung(false);
      });
  }, [FID]);

  // ✅ Stopps & Anwesenheitsdaten laden und Vergütung berechnen
  useEffect(() => {
    if (!FID || loadingVerguettung) return;
    setLoading(true);

    const stoppsRef = ref(db, `dailybusiness/${FID}/${milltoDay}`);

    onValue(stoppsRef, (snapshot) => {
      if (snapshot.exists()) {
        console.log("data->", snapshot.val())
        const data = snapshot.val();
        let stoppsList = [];
        let totalBetrag = 0;
        let totalStopps = 0;
        let count = 0;

        if (data.anwesenheit) {
          Object.entries(data.anwesenheit).forEach(([userID, value]) => {
            count++;
            let UStopps = data.stopps
              ? Object.values(data.stopps).filter((x) => x.userid === userID)
              : [];

            // ✅ Passende Vergütung finden
            const verguettungData = verguettung.find((item) =>
              item.tourassign?.some((tour) => tour.label === value.Tour)
            );

            let gage = 0;
            let maut = verguettungData?.maut || 0;
            let diesel = 0;
            let verguettungOhneDieselundMaut = 0;

            if (verguettungData && UStopps.length > 0) {
              let grundsumme =
                parseFloat(verguettungData.pauschale) +
                UStopps.length * parseFloat(verguettungData.prostopp);
              diesel =
                grundsumme *
                (parseFloat(verguettungData.diselzuschlag) / 100);
              verguettungOhneDieselundMaut = grundsumme;
              gage = grundsumme + diesel + maut;
            }

            stoppsList.push({
              id: userID,
              tour: value.Tour || "Unbekannt",
              sls: value.SLS || 0,
              stopps: UStopps.length,
              ladestelle: value.beentladepunkt,
              feierabendzone: value.zonefeierabend,
              present: value.present,
              tagesabschluss: value.tagesabschluss,
              gage: gage,
              verguettungOhneDieselundMaut:
                verguettungOhneDieselundMaut.toFixed(2),
              maut: maut,
              diesel: diesel.toFixed(2),
            });

            totalBetrag += parseFloat(gage);
            totalStopps += UStopps.length;
          });
        }

        setStoppsData(stoppsList);
        setGesamtBetrag(totalBetrag);
        setGesamtStopps(totalStopps);
        setDurchschnittBetrag(totalBetrag / count || 0);
        setDurchschnittstopps(totalStopps / count || 0);
      }
      setLoading(false);
    });
  }, [FID, milltoDay, verguettung, loadingVerguettung]);

  const numberFormat = (value) =>
    new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(value);

  // ✅ Farbformatierung
  const formatStopps = (cell) => (
    <span
      style={{
        color: cell >= 20 ? "green" : cell >= 16 ? "blue" : "red",
      }}
    >
      <strong>{cell}</strong>
    </span>
  );

  const formatTour = (cell, row) => (
    <span>
      <strong style={{ color: "blue" }}>{cell}</strong>
      <div className="d-flex justify-content-around">
        {row.ladestelle ? (
          <i className="bi bi-box-seam text-success"></i>
        ) : (
          <i className="bi bi-box-seam text-danger"></i>
        )}
        {row.feierabendzone ? (
          <i className="bi bi-p-circle text-success"></i>
        ) : (
          <i className="bi bi-p-circle text-danger"></i>
        )}
        {row.tagesabschluss ? (
          <i className="bi bi-check-circle-fill text-success"></i>
        ) : (
          <i className="bi bi-check-circle text-danger"></i>
        )}
      </div>
    </span>
  );

  const formatVerguettung = (cell) => {
    const num = parseFloat(cell) || 0;
    return (
      <span
        style={{
          color: num >= 500 ? "green" : num >= 460 ? "blue" : "red",
        }}
      >
        <strong>{numberFormat(num)}</strong>
      </span>
    );
  };

  // Spalten: Desktop-Version (mehrere Spalten)
  const columnsDesktop = [
    {
      dataField: "tour",
      text: "Tour",
      formatter: formatTour,
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "sls",
      text: "SLS",
      formatter: formatStopps,
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "stopps",
      text: "Stopps",
      formatter: formatStopps,
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "verguettungOhneDieselundMaut",
      text: "o. D. & M.",
      formatter: formatVerguettung,
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "maut",
      text: "Maut",
      formatter: formatVerguettung,
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "diesel",
      text: "Diesel",
      formatter: formatVerguettung,
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "gage",
      text: "Gesamt Gage",
      formatter: formatVerguettung,
      align: "center",
      headerAlign: "center",
      sort: true,
    },
  ];

  // Spalten: Mobile-Version (alle Teilwerte in einer einzigen Spalte)
  const columnsMobile = [
    {
      dataField: "tour",
      text: "Tour",
      formatter: formatTour,
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "sls",
      text: "SLS",
      formatter: formatStopps,
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "stopps",
      text: "Stopps",
      formatter: formatStopps,
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "gage", // wir verwenden "gage" nur als "Basis" für Sortierung
      text: "Vergütung",
      sort: true,
      align: "center",
      headerAlign: "center",
      // Custom Formatter: alle Werte untereinander
      formatter: (cell, row) => {
        // Mit deiner Hilfsfunktion:
        const odmElem    = formatColored(row.verguettungOhneDieselundMaut, numberFormat);
        const mautElem   = formatColored(row.maut, numberFormat);
        const dieselElem = formatColored(row.diesel, numberFormat);
        const gesamElem  = formatColored(row.gage, numberFormat);
      
        return (
          <div style={{ minWidth: "140px" }}>
            <div>{odmElem}</div>
            <div>{mautElem}</div>
            <div>{dieselElem}</div>
            <hr className="my-1" />
            <div>
              <strong>Gesamt: </strong>{gesamElem}
            </div>
          </div>
        );
      },
      
      // Eigene Sortierfunktion, weil wir 'gage' als Basiswert für die Sortierung nehmen wollen
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const valA = parseFloat(rowA.gage) || 0;
        const valB = parseFloat(rowB.gage) || 0;
        if (order === "asc") {
          return valA - valB;
        }
        return valB - valA;
      },
    },
  ];

  // Falls 'stoppsdata' leer, setze alle Summen auf 0
  useEffect(() => {
    if (stoppsdata.length === 0) {
      setGesamtBetrag(0);
      setGesamtStopps(0);
      setDurchschnittBetrag(0);
      setDurchschnittstopps(0);
    }
  }, [stoppsdata]);

  function formatColored(value, numberFormat) {
    const num = parseFloat(value) || 0;
    let color;
    if (num >= 500) color = "green";
    else if (num >= 460) color = "blue";
    else color = "red";
  
    return (
      <span style={{ color }}>
        <strong>{numberFormat(num)}</strong>
      </span>
    );
  }

  // Wähle Spalten-Set abhängig von der Breite:
  const columns = screenWidth < 768 ? columnsMobile : columnsDesktop;

  return (
    <Container className="mt-3">
      
      <Card className="mt-3 bg-light align-items-stretch ">
        <div className="d-flex justify-content-around align-items-center">
          <Button
            variant="outline-success"
            onClick={() => {
              stoppsdata.length = 0;
              const newDate = subDays(milltoDay, 1);
              setMilltoDay(newDate.setUTCHours(0, 0, 0, 0));
              setDayShow(newDate);
            }}
          >
            ⬅
          </Button>

          <div className="mt-3 text-center">
            <h1>Gesamt: {numberFormat(gesamtbetrag)}</h1>
            <h3>{gesamtstopps} Stopps</h3>
            <h1>Durchschnitt: {numberFormat(durchschnittbetrag)}</h1>
            <h3>{durchschnittstopps.toFixed(2)} Stopps</h3>
            <h5>Daten für den {format(dayshow, "dd.MM.yyyy")}</h5>
          </div>

          <Button
            variant="outline-success"
            onClick={() => {
              stoppsdata.length = 0;
              const newDate = addDays(milltoDay, 1);
              setMilltoDay(newDate.setUTCHours(0, 0, 0, 0));
              setDayShow(newDate);
            }}
          >
            ➡
          </Button>
        </div>

        <Card.Body>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
              <p>Daten werden geladen...</p>
            </div>
          ) : stoppsdata.length === 0 ? (
            <div className="text-center">
              <p className="text-danger">
                <strong>⚠ Keine Daten für den ausgewählten Tag verfügbar.</strong>
              </p>
            </div>
          ) : (
            // WICHTIG: "wrapperClasses" für das horizontale Scrollen
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={stoppsdata}
              columns={columns}
              wrapperClasses="table-responsive"
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};
