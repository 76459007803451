import React, { useState, useEffect } from "react";
import {
  ref,
  getDatabase,
  query,
  update,
  push,
  child,
  onValue,
} from "firebase/database";
import { useObjectVal } from "react-firebase-hooks/database";
import { Card, Form, Button, Tabs, Tab, Spinner } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import { useAuth } from "../../../../context/authContext";
import GeneralInfoTab from "./GeneralInfoTab";
import SpecificDataTab from "./SpecificDataTab";
import ExpiryDatesTab from "./ExpiryDatesTab";
import useWindowDimensions from "../../../useMediaQuery";

registerLocale("de", de);

function MForm({
  showNewUser,
  childToParent,
  setShowNewUser,
  formdata,
  IsnewUser,
}) {
  const db = getDatabase();
  const { FID } = useAuth();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const [passwort, setPasswort] = useState("");
  const [validated, setValidated] = useState(true);
  const [userid, setuserid] = useState("");

  const [data, setFormData] = useState({
    color: "#697689",
    aktiveDate: new Date().getTime(),
    createDate: new Date().getTime(),
    fid: FID,
    isAktiv: true,
    pathlohn: "",
    isLohnabrechnungen: true,
    isSpesen: true,
    isDelete: false,
    name: "",
    email: "",
    telefon: "",
    strasse: "",
    plz: "",
    ort: "",
    rollen: [{ value: 40, label: "Fahrer" }],
  });

  const [expiryData, setExpiryData] = useState({
    adr: {
      actualAppointmentDate: 0,
      appointmentDate: 0,
      appointmentMade: false,
      duedateinterval: 1800,
      expiryDate: 0,
    },
    bkrfqg: {
      actualAppointmentDate: 0,
      appointmentDate: 0,
      appointmentMade: false,
      duedateinterval: 1800,
      expiryDate: 0,
    },
    fahrerkarte: {
      actualAppointmentDate: 0,
      appointmentDate: 0,
      appointmentMade: false,
      duedateinterval: 1800,
      expiryDate: 0,
    },
    fahrerkarteauslesen: {
      duedateinterval: 27,
      expiryDate: 0,
    },
    firmeneintritt: 0,
    fuehrerschein: {
      actualAppointmentDate: 0,
      appointmentDate: 0,
      appointmentMade: false,
      duedateinterval: 1800,
      expiryDate: 0,
    },
    geburtstag: 0,
  });

  const [AZeiten, setAZeiten] = useState([
    { active: false, beginn: null, ende: null }, // Montag (index 0)
    { active: false, beginn: null, ende: null }, // Dienstag (index 1)
    { active: false, beginn: null, ende: null }, // Mittwoch (index 2)
    { active: false, beginn: null, ende: null }, // Donnerstag (index 3)
    { active: false, beginn: null, ende: null }, // Freitag (index 4)
    { active: false, beginn: null, ende: null }, // Samstag (index 5)
    { active: false, beginn: null, ende: null }, // Sonntag (index 6)
  ]);

  const defaultSevenDays = [
    { active: false, beginn: null, ende: null }, // Montag
    { active: false, beginn: null, ende: null }, // Dienstag
    { active: false, beginn: null, ende: null }, // Mittwoch
    { active: false, beginn: null, ende: null }, // Donnerstag
    { active: false, beginn: null, ende: null }, // Freitag
    { active: false, beginn: null, ende: null }, // Samstag
    { active: false, beginn: null, ende: null }, // Sonntag
  ];

  

  const options = [
    { value: 10, label: "Admin" },
    { value: 20, label: "Fuhrparkleiter" },
    { value: 30, label: "Fahrer+" },
    { value: 40, label: "Fahrer" },
  ];

  const UMHDRef = query(ref(db, `mhd/` + FID + "/" + formdata.key));
  const [MHDvalues, loadingMHD] = useObjectVal(UMHDRef);

  useEffect(() => {
    if (!IsnewUser && formdata.key) {
      // oder: if(formdata.key) { ... }, je nachdem ob du die Arbeitszeiten
      // NUR beim Bearbeiten laden möchtest

      const UArbeitszeitenRef = ref(db, `arbeitzeiten/${FID}/${formdata.key}`);

      // onValue(...) gibt einen unsubscribe-Callback zurück
      const unsubscribe = onValue(UArbeitszeitenRef, (snapshot) => {
        if (snapshot.exists()) {
          const val = snapshot.val();
          if (val.arbeitzeiten) {
            const loaded  = val.arbeitzeiten.map((item) => ({
              active: !!(item.arbeitsbeginn && item.arbeitsende),
              beginn: item.arbeitsbeginn ? new Date(item.arbeitsbeginn) : null,
              ende: item.arbeitsende ? new Date(item.arbeitsende) : null,
            }));

            const mergedAZ = defaultSevenDays.map((day, index) => {
              if (val.arbeitzeiten && val.arbeitzeiten[index]) {
                const item = val.arbeitzeiten[index];
                return {
                  active: !!(item.arbeitsbeginn && item.arbeitsende),
                  beginn: item.arbeitsbeginn ? new Date(item.arbeitsbeginn) : null,
                  ende: item.arbeitsende ? new Date(item.arbeitsende) : null
                };
              }
              return day;
            });
            setAZeiten(mergedAZ);
          }else {
            // Falls noch keine arbeitzeiten in Firebase -> setze 7 Tage Default
            setAZeiten(defaultSevenDays);
          }
        } else {
          // Falls gar kein Snapshot existiert -> 7 Tage Default
          setAZeiten(defaultSevenDays);
        }        
      });

      // Cleanup: Bei Unmount oder wenn Effekt neu gebunden wird
      return () => {
        // Einfach die unsubscribe-Funktion aufrufen
        unsubscribe();
      };
    }
  }, [db, FID, formdata.key, IsnewUser]);

  useEffect(() => {
    if (!loadingMHD && !IsnewUser && MHDvalues) {
     
      setExpiryData(MHDvalues); // Direkt als Objekt speichern
    }
  }, [loadingMHD, IsnewUser, MHDvalues]);

  useEffect(() => {
    if (!IsnewUser) {
      setuserid(formdata.key);
      setFormData(formdata.user);
    }
  }, [IsnewUser, formdata]);

  const handleDateChange = (field, date, subField = null) => {
    if (field === "geburtstag" || field === "firmeneintritt") {
      setExpiryData((prevState) => ({
        ...prevState,
        [field]: date ? date : 0, // 🔹 Direkt als Zahl speichern, kein `expiryDate`
      }));
    } else {
     
      setExpiryData((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          [subField]: date ? date : 0,
        },
      }));
    }
  };

  const saveUserToFirebase = () => {
    const updates = {};
    const newUserKey = IsnewUser
      ? push(child(ref(db), "users/" + FID)).key
      : userid;

    updates[`/users/${FID}/${newUserKey}`] = data;
    updates[`/mhd/${FID}/${newUserKey}`] = expiryData; // ✅ Speichern als Objekt in Firebase

    return update(ref(db), updates);
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(e) => {
        e.preventDefault();
        //saveUserToFirebase();
        //setShowNewUser(false);
        childToParent(e, data, AZeiten, expiryData, userid, passwort);
      }}
      className="mt-3"
    >
      <Card className="align-items-stretch myCard">
        <Card.Header className="text-center">
          {IsnewUser
            ? "Neuen Mitarbeiter hinzufügen"
            : `Mitarbeiter ${data.name} bearbeiten`}
        </Card.Header>
        <Card.Body className="d-flex flex-column">
          <Tabs defaultActiveKey="home" className="mb-3">
            <Tab eventKey="home" title="Allgemeine Angaben">
              <GeneralInfoTab
                data={data}
                setFormData={setFormData}
                passwort={passwort}
                setPasswort={setPasswort}
              />
            </Tab>
            <Tab eventKey="profile" title="Spezifische Daten">
              <SpecificDataTab
                data={data}
                setFormData={setFormData}
                options={options}
                AZeiten={AZeiten}
                setAZeiten={setAZeiten}
              />
            </Tab>
            <Tab eventKey="date" title="Verfalldaten">
              <ExpiryDatesTab
                expiryData={expiryData}
                handleDateChange={handleDateChange}
              />
            </Tab>
          </Tabs>
        </Card.Body>
        <Card.Footer>
          <div className="row">
            <div className="col d-grid gap-2 mt-auto">
              <Button
                variant="outline-danger"
                onClick={() => setShowNewUser(false)}
              >
                Abbrechen
              </Button>
            </div>
            <div className="col d-grid gap-2 mt-auto">
              <Button
                className="float-right"
                variant="outline-success"
                type="submit"
              >
                {IsnewUser ? "Speichern" : "Update"}
              </Button>
            </div>
          </div>
        </Card.Footer>
      </Card>
    </Form>
  );
}

export default MForm;
