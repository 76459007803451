import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Container, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import feiertage from "moment-feiertage";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getDatabase, ref, onValue } from "firebase/database";

const localizer = momentLocalizer(moment);
moment.locale("de");

export function Monatsansicht() {
  const location = useLocation();
  const navigate = useNavigate();
  const { year,FID, UID, isAdmin, currentMitarbeiter } = location.state || {}; // Jahr via state übergeben
  const finalYear = year ? Number(year) : new Date().getFullYear();

  // Initialisiere das Datum auf den 1. Januar des finalYear
  const initialDate = new Date(finalYear, 0, 1);
  const [currentDate, setCurrentDate] = useState(initialDate);
  const [urlaubsDaten, setUrlaubsDaten] = useState([]);

  const db = getDatabase();

  useEffect(() => {
    if (!FID || !finalYear || !UID) return;

    let refPath;
    if (isAdmin) {
      // Admins: Daten liegen verschachtelt nach Mitarbeiter-IDs vor
      refPath = `kalender/${FID}/${finalYear}`;
    } else {
      // Normale User: Flache Struktur unter der eigenen UID
      refPath = `kalender/${FID}/${finalYear}/${UID}`;
    }
    const urlaubRef = ref(db, refPath);

    onValue(urlaubRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let formattedData = [];
        if (isAdmin) {
          // Durchlaufe alle Mitarbeiter und deren Events
          Object.keys(data).forEach((userId) => {
            const userEvents = data[userId];
            Object.keys(userEvents).forEach((eventId) => {
              const event = userEvents[eventId];
              const employeeName =
              currentMitarbeiter &&
              currentMitarbeiter[userId] &&
              currentMitarbeiter[userId].name
                  ? currentMitarbeiter[userId].name
                  : "";
              // Titel: z.B. "Krankheit Hubert Stetin"
              const title = employeeName
                ? `${event.anlass || "Urlaub"} ${employeeName}`
                : event.anlass || "Urlaub";

              formattedData.push({
                id: eventId,
                userId,
                title,
                start: moment(event.startDate, "DD.MM.YYYY").toDate(),
                end: moment(event.endDate, "DD.MM.YYYY").endOf("day").toDate(),
                status: event.status,
                ...event,
              });
            });
          });
        } else {
          // Bei normalen Usern liegt die Struktur flach vor
          formattedData = Object.keys(data).map((key) => {
            const event = data[key];
            return {
              id: key,
              title: event.anlass || "Urlaub",
              start: moment(event.startDate, "DD.MM.YYYY").toDate(),
              end: moment(event.endDate, "DD.MM.YYYY").endOf("day").toDate(),
              status: event.status,
              ...event,
            };
          });
        }
        setUrlaubsDaten(formattedData);
      } else {
        setUrlaubsDaten([]);
      }
    });
  }, [FID, db, finalYear, UID, isAdmin, currentMitarbeiter]);

  // Feiertage berechnen
  const getHolidaysForYear = (year) => {
    const holidays = [];
    let day = moment(`${year}-01-01`);
    const endDate = moment(`${year}-12-31`);
    while (day.isSameOrBefore(endDate, "day")) {
      const holidayName = feiertage(day).isHoliday("HH");
      if (holidayName) {
        holidays.push({
          title: holidayName,
          start: day.toDate(),
          end: day.toDate(),
          allDay: true,
          holiday: true,
        });
      }
      day.add(1, "day");
    }
    return holidays;
  };

  const holidayEvents = getHolidaysForYear(finalYear);

  // Kombiniere Feiertage und Urlaubsdaten
  const calendarEvents = [...holidayEvents, ...urlaubsDaten];

  // Farbschemata für Status und Anlass
  const statusColors = {
    genehmigt: { background: "#28a745", text: "white" },
    wartend: { background: "#ffc107", text: "black" },
    abgelehnt: { background: "#dc3545", text: "white" },
  };

  const anlassColors = {
    Krankheit: { background: "#6f42c1", text: "white" },
    Urlaub: { background: "#007bff", text: "white" },
    Homeoffice: { background: "#17a2b8", text: "white" },
    Dienstreise: { background: "#fd7e14", text: "white" },
    Fortbildung: { background: "#6610f2", text: "white" },
  };

  const eventStyleGetter = (event) => {
    // Feiertage behalten den eigenen Stil
    if (event.holiday) {
      return {
        style: {
          backgroundColor: "green",
          border: "none",
          color: "white",
        },
      };
    }
    // Zuerst: nach Status einfärben
    if (event.status && statusColors[event.status]) {
      return {
        style: {
          backgroundColor: statusColors[event.status].background,
          border: "none",
          color: statusColors[event.status].text,
        },
      };
    }
    // Falls kein Status, aber ein definierter Anlass
    if (event.anlass && anlassColors[event.anlass]) {
      return {
        style: {
          backgroundColor: anlassColors[event.anlass].background,
          border: "none",
          color: anlassColors[event.anlass].text,
        },
      };
    }
    // Fallback
    return {
      style: {
        backgroundColor: "gray",
        border: "none",
        color: "white",
      },
    };
  };

  const handleNavigate = (newDate) => {
    if (newDate.getFullYear() !== finalYear) {
      if (newDate.getFullYear() < finalYear) {
        setCurrentDate(new Date(finalYear, 0, 1));
      } else {
        setCurrentDate(new Date(finalYear, 11, 1));
      }
    } else {
      setCurrentDate(newDate);
    }
  };

  const messages = {
    today: "Heute",
    previous: "Zurück",
    next: "Nächster",
    month: "Monat",
    week: "Woche",
    day: "Tag",
    agenda: "Agenda",
    date: "Datum",
    time: "Uhrzeit",
    event: "Termin",
    allDay: "Ganztägig",
    noEventsInRange: "Keine Termine in diesem Zeitraum",
    showMore: (total) => `+${total} mehr`,
  };

  return (
    <Container className="my-3 mx-auto">
      <Button
        variant="secondary"
        onClick={() => navigate("/kalender")}
        className="mb-3"
      >
        Zurück zur Urlaubsverwaltung
      </Button>
      <Calendar
        localizer={localizer}
        events={calendarEvents}
        date={currentDate}
        onNavigate={handleNavigate}
        views={["month"]}
        eventPropGetter={eventStyleGetter}
        messages={messages}
        popup  // Aktiviert den Popup-Modus: "+X mehr"-Link erscheint automatisch
        style={{ height: "80vh" }}
      />
    </Container>
  );
}
