import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import "../../../App.css";
import { Card, Button, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, {
  textFilter,
  selectFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import useWindowDimensions from "../../useMediaQuery";
import {
  ref,
  getDatabase,
  update,
  get,
  child,
  remove,
} from "firebase/database";
import { useAuth } from "../../../context/authContext";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "bootstrap-icons/font/bootstrap-icons.css";

export const StoppsAuswahl = ({
  childToParent,
  setShowStoppAuswahl,
  stoppauswahl,
}) => {
  const db = getDatabase();
  const dbRef = ref(getDatabase());

  // Referenz zum Drucken (react-to-print)
  const tableRef = useRef();

  const { currentUser } = useAuth();
  const [FID, setFID] = useState("");

  const [loading, setLoading] = useState(true);
  const [Stopps, setStopps] = useState({});
  const [columns, setcolumns] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  // Für Gage-Berechnung
  const [gage, setGage] = useState([]);
  const [gagegesamt, setGageGesamt] = useState([]);
  const [verguettung, setVerguettung] = useState([]);

  // User / Tour / Fahrzeug
  const [loadingUser, setLoadingUser] = useState(true);
  const [SortUser, setSortUser] = useState({});
  const [loadingTour, setLoadingTour] = useState(true);
  const [SortTour, setSortTour] = useState({});
  const [loadingFahrzeug, setLoadingFahrzeug] = useState(true);
  const [SortFahrzeug, setSortFahrzeug] = useState({});

  const { width } = useWindowDimensions();
  const delsign = <i className="bi bi-trash"></i>;

  // Hook für die Druck-Funktion
  const handlePrint = useReactToPrint({
    content: () => {
      console.log("tableRef.current:", tableRef.current);
      return tableRef.current;
    },
    documentTitle: "Stopps-Auswahl",
    // removeAfterPrint: true, // Optional
  });

  // Nach dem Einloggen FID setzen
  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser]);

  // Verguetung laden
  useEffect(() => {
    if (!FID) return;
    get(child(ref(getDatabase()), `verguetung/` + FID))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const unsort = Object.keys(data).map((k) => ({
            id: k,
            ...data[k],
          }));
          const resual = unsort.filter((v) => v.isAktiv === true);
          setVerguettung(resual);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [FID]);

  // User laden
  useEffect(() => {
    if (!FID) return;
    get(child(dbRef, `users/` + FID))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const unsort = Object.keys(data).map((k) => ({
            id: k,
            ...data[k],
          }));
          const resual = unsort.filter((user) => user.isDelete === false);
          setSortUser(resual);
          setLoadingUser(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dbRef, FID]);

  // Touren laden
  useEffect(() => {
    if (!FID || loadingUser) return;
    get(child(dbRef, `touren/` + FID))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const unsort = Object.keys(data).map((k) => ({
            id: k,
            ...data[k],
          }));
          const resual = unsort.filter((tour) => tour.isAktiv === true);
          setSortTour(resual);
          setLoadingTour(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dbRef, FID, loadingUser]);

  // Fahrzeuge laden
  useEffect(() => {
    if (!FID || loadingTour) return;
    get(child(dbRef, `fahrzeuge/` + FID))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const unsort = Object.keys(data).map((k) => ({
            id: k,
            ...data[k],
          }));
          const resual = unsort.filter((fahrzeug) => fahrzeug.isAktiv === true);
          setSortFahrzeug(unsort);
          setLoadingFahrzeug(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dbRef, FID, loadingTour]);

  // Stopps laden und Tabelle aufbauen
  useEffect(() => {
    if (
      loadingUser ||
      loadingTour ||
      loadingFahrzeug ||
      !verguettung ||
      !loading
    )
      return;

    let newStopps = [];
    let vT = [];

    const optionArt = [
      { value: "erfolgreich", label: "erfolgreich" },
      { value: "abholer", label: "abholer" },
      { value: "vergeblich", label: "vergeblich" },
    ];
    const optionUser = [];
    const optionTour = [];
    const optionFahrzeug = [];

    // DropDown-Optionen
    Object.values(SortUser).forEach((val) =>
      optionUser.push({ value: val.id, label: val.name })
    );
    Object.values(SortTour).forEach((val) =>
      optionTour.push({ value: val.id, label: val.bezeichnung })
    );
    Object.values(SortFahrzeug).forEach((val) =>
      optionFahrzeug.push({ value: val.id, label: val.kennzeichen })
    );

    let tagecount = 0;

    // Aus stoppauswahl (Props) alle Einträge durchgehen
    Object.entries(stoppauswahl).forEach(([key, value]) => {
      const Datum = value.key;
      tagecount++;

      // Stopp-Einträge
      Object.entries(value["stopps"]).forEach(([k1, val]) => {
        const id = k1;
        const Verguettungsresult = verguettung.find((item) =>
          item.tourassign.find((tour) => tour.value === val["tourid"])
        );

        // Noch nicht in vT => neu anlegen
        if (!vT.find((o) => o.id === val["tourid"])) {
          vT.push({
            id: val["tourid"],
            tage: 0,
            tournummer: optionTour.find((x) => x.value === val["tourid"])
              ?.label,
            proS: Number(Verguettungsresult?.prostopp || 0),
            count: 1,
            maut: Number(Verguettungsresult?.maut || 0),
            pauschale: Number(Verguettungsresult?.pauschale || 0),
            diselzuschlag: Number(Verguettungsresult?.diselzuschlag || 0),
            gproStopp: Number(Verguettungsresult?.prostopp || 0),
          });
        } else {
          // Bereits vorhanden => count++
          const dvt = vT.find((o) => o.id === val["tourid"]);
          dvt.count += 1;
          dvt.tage = tagecount;
          dvt.gproStopp += dvt.proS;
        }

        // Für Tabelle
        newStopps.push({
          datum: Datum,
          id: id,
          adress: val["adress"],
          firmname: val["firmname"],
          art: val["art"],
          createdatetime: val["createdatetime"],
          distance: val["distance"],
          elapsedTime: val["elapsedTime"],
          fzid: val["fzid"],
          tourid: val["tourid"],
          userid: val["userid"],
        });
      });

      vT.sort();
      setGage(vT);

      setRowCount(newStopps.length);
      setcolumns([
        {
          dataField: "id",
          text: "ID",
          hidden: true,
          editable: false,
        },
        {
          dataField: "datum",
          text: "Datum",
          sort: true,
          editable: false,
          formatter: formatDate,
          filter: dateFilter(),
          align: "center",
        },
        {
          dataField: "adress",
          text: "Adresse",
          sort: true,
          editable: true,
          filter: textFilter(),
        },
        {
          dataField: "firmname",
          text: "Firma",
          sort: true,
          editable: true,
          filter: textFilter(),
        },
        {
          dataField: "art",
          text: "Art",
          align: "center",
          formatter: formatArt,
          filter: selectFilter({
            options: optionArt,
          }),
          editor: {
            type: Type.SELECT,
            options: optionArt,
          },
        },
        {
          dataField: "createdatetime",
          text: "Zeit",
          sort: true,
          editable: false,
          classes: "no-print", 
          headerClasses: "no-print",
          formatter: formatTime,
          align: "center",
        },
        {
          dataField: "distance",
          text: "Fahrweg",
          formatter: formatMeterinKM,
          editable: false,
          classes: "no-print", 
          headerClasses: "no-print",
          align: "center",
        },
        {
          dataField: "elapsedTime",
          text: "Zeitaufwand",
          formatter: formatSecondToTime,
          editable: false,
          align: "center",
          classes: "no-print",    
          headerClasses: "no-print",
        },
        {
          dataField: "fzid",
          text: "Kennzeichen",
          formatter: (cell) => {
            return optionFahrzeug.find((x) => x.value === cell)?.label || "";
          },
          sort: true,
          align: "center",
          filter: selectFilter({
            options: optionFahrzeug,
          }),
          editor: {
            type: Type.SELECT,
            options: optionFahrzeug,
          },
        },
        {
          dataField: "tourid",
          text: "Tour",
          align: "center",
          formatter: (cell) => {
            return optionTour.find((x) => x.value === cell)?.label || "";
          },
          sort: true,
          filter: selectFilter({
            options: optionTour,
          }),
          editor: {
            type: Type.SELECT,
            options: optionTour,
          },
        },
        {
          dataField: "userid",
          text: "Name",
          formatter: (cell) => {
            return optionUser.find((x) => x.value === cell)?.label || "";
          },
          sort: true,
          filter: selectFilter({
            options: optionUser,
          }),
          editor: {
            type: Type.SELECT,
            options: optionUser,
          },
        },
        {
          dataField: "remove",
          text: "Löschen",
          editable: false,
          align: "center",
          classes: "no-print",
          headerClasses: "no-print",
          formatter: (cellContent, row) => {
            return (
              <Button
                variant="outline-danger"
                row={row}
                size="sm"
                onClick={() => handleDelete(row)}
              >
                {delsign} Löschen
              </Button>
            );
          },
        },
      ]);

      setStopps(newStopps);
      setLoading(false);
    });
  }, [
    loadingUser,
    loadingTour,
    loadingFahrzeug,
    verguettung,
    stoppauswahl,
    loading,
  ]);

  // Gage berechnen
  useEffect(() => {
    let gesamt = 0;
    Object.values(gage).forEach((item) => {
      const gageOMOD = item.gproStopp + item.tage * item.pauschale;
      const gageMDiesel = gageOMOD + (gageOMOD / 100) * item.diselzuschlag;
      const gagegesamt = gageMDiesel + item.tage * item.maut;
      gesamt += gagegesamt;
    });
    setGageGesamt(currencyNumberFormat(gesamt));
  }, [gage]);

  // Hilfsfunktionen
  const formatDate = (timestamp) => {
    return new Intl.DateTimeFormat("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(timestamp);
  };

  const formatTime = (timestamp) => {
    return new Intl.DateTimeFormat("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timestamp);
  };

  const formatSecondToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  const formatMeterinKM = (meter) => {
    const Strecke = meter / 1000;
    return Strecke.toFixed(2) + " km";
  };

  const formatArt = (cell) => {
    if (cell === "erfolgreich") {
      return <strong style={{ color: "green" }}>{cell}</strong>;
    } else if (cell === "abholer") {
      return <strong style={{ color: "SteelBlue" }}>{cell}</strong>;
    }
    return <strong style={{ color: "red" }}>{cell}</strong>;
  };

  const currencyNumberFormat = (value) => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(value);
  };

  // Zelle speichern
  function beforeSaveCell(oldValue, newValue, row, column, done) {
    setTimeout(() => {
      const fieldToUpdate = column.dataField;
      const updates = {};
      updates[
        `/dailybusiness/${FID}/${row.datum}/stopps/${row.id}/${fieldToUpdate}`
      ] = newValue;
      update(dbRef, updates)
        .then(() => {
          done(true);
        })
        .catch(() => {
          done(false);
        });
    }, 0);
    return { async: true };
  }

  // Löschen
  const handleDelete = (row) => {
    const username = SortUser.find((x) => x.id === row.userid);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Bist du dir Sicher?</h1>
            <p>Den Stopp von {username?.name} wirklich löschen?</p>
            <div className="d-flex justify-content-evenly">
              <Button variant="outline-primary" onClick={onClose}>
                Abbrechen
              </Button>
              <Button
                variant="outline-danger"
                onClick={() => {
                  const removes = ref(
                    db,
                    `/dailybusiness/${FID}/${row.datum}/stopps/${row.id}`
                  );
                  remove(removes);
                  onClose();
                }}
              >
                Ja, lösche!
              </Button>
            </div>
          </div>
        );
      },
    });
  };

  // DataSize-Callback (Anzahl gefilterter Einträge)
  const handleDataChange = (Size) => {
    setRowCount(Size.dataSize);
  };

  const defaultSorted = [
    {
      dataField: "userid",
      order: "desc",
    },
  ];

  if (!loading) {
    return (
      <div className="table-responsive">
        <Card className="mt-3 bg-light align-items-stretch myCardsx">
          <Card.Header className="text-center">
            {/* Button zum Drucken */}
            <Button
              onClick={() => {
                console.log("Druck-Button geklickt");
                handlePrint();
              }}
            >
              Tabelle drucken
            </Button>

            <h4>Angaben über die Stopps</h4>
            <h5>Stopps Anzahl: {rowCount}</h5>
            <h5>Gesamt Umsatz: {gagegesamt}</h5>

            <div className="card-group">
              {gage
                .sort((a, b) => (a.tournummer > b.tournummer ? 1 : -1))
                .map((item) => (
                  <Col key={item.id}>
                    <Card
                      style={{
                        borderColor: "lightblue",
                        borderRadius: "30px",
                      }}
                      className="mx-1"
                    >
                      <Card.Header
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "lightblue",
                          borderTopLeftRadius: "30px",
                          borderTopRightRadius: "30px",
                        }}
                      >
                        {item.tournummer}
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>Anzahl Stopps: {item.count}</Card.Title>
                        <Card.Text>
                          <strong style={{ fontSize: 16, color: "blue" }}>
                            Umsatz: {calgage(item)}
                          </strong>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </div>
          </Card.Header>

          <Card.Body className="d-flex flex-column">
            {/* Der Bereich, der ausgedruckt werden soll */}
            <div
              ref={tableRef}
              className="print-container"             
            >
              <BootstrapTable
                bootstrap4={true}
                defaultSorted={defaultSorted}
                keyField="id"
                data={Stopps}
                columns={columns}
                striped
                hover
                bordered={true}                
                wrapperClasses="table-responsive"
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  beforeSaveCell,
                })}
                filter={filterFactory()}
                noDataIndication="Keine Daten zum Anzeigen."
                pagination={paginationFactory({
                  showTotal: true,
                  hidePageListOnlyOnePage: true,
                })}
                classes="table-with-pagination"
                onDataSizeChange={(dataSize) => {
                  handleDataChange(dataSize);
                }}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  } else {
    // Wenn loading == true
    return <div>Lade Daten …</div>;
  }
};

// Gage-Helfer: Reine Anzeige
function calgage(item) {
  if (!item) return "0,00 €";
  const {
    gproStopp = 0,
    tage = 0,
    pauschale = 0,
    diselzuschlag = 0,
    maut = 0,
  } = item;
  const gageOMOD = gproStopp + tage * pauschale;
  const gageMDiesel = gageOMOD + (gageOMOD / 100) * diselzuschlag;
  const gagegesamt = gageMDiesel + tage * maut;
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(gagegesamt);
}
