import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import feiertage from "moment-feiertage"; // Plugin, das moment um die Methode feiertag() erweitert
import { Row, Col, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getDatabase, ref, onValue } from "firebase/database";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
moment.locale("de");

export function Jahresansicht() {
  const location = useLocation();
  const { year,FID, UID, isAdmin, currentMitarbeiter } = location.state || {}; // Jahr via state übergeben
  const displayYear = year || moment().year();
  const navigate = useNavigate();
  
  // State für die in Firebase gespeicherten Urlaubs-/Event-Daten
  const [firebaseEvents, setFirebaseEvents] = useState([]);

  // Firebase-Daten laden
  useEffect(() => {
    const db = getDatabase();
    if (!FID || !displayYear || !UID) return;

    let refPath;
    if (isAdmin) {
      // Für Admins: Daten sind verschachtelt nach Mitarbeiter-IDs
      refPath = `kalender/${FID}/${displayYear}`;
    } else {
      // Für normale User: Nur eigene Events laden
      refPath = `kalender/${FID}/${displayYear}/${UID}`;
    }
    const eventRef = ref(db, refPath);
    onValue(eventRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let formattedData = [];
        if (isAdmin) {
          // Durchlaufe alle Mitarbeiter und deren Events
          Object.keys(data).forEach((userId) => {
            const userEvents = data[userId];
            Object.keys(userEvents).forEach((eventId) => {
              const event = userEvents[eventId];
              // Falls ein Mitarbeitername vorhanden ist, diesen dem Eventtitel hinzufügen
              const employeeName =
              currentMitarbeiter &&
              currentMitarbeiter[userId] &&
              currentMitarbeiter[userId].name
                  ? currentMitarbeiter[userId].name
                  : "";
              const title = employeeName
                ? `${event.anlass || "Urlaub"} ${employeeName}`
                : event.anlass || "Urlaub";
              formattedData.push({
                id: eventId,
                userId,
                title,
                start: moment(event.startDate, "DD.MM.YYYY").toDate(),
                end: moment(event.endDate, "DD.MM.YYYY").endOf("day").toDate(),
                status: event.status,
                ...event,
              });
            });
          });
        } else {
          // Bei normalen Usern ist die Struktur flach
          formattedData = Object.keys(data).map((key) => {
            const event = data[key];
            return {
              id: key,
              title: event.anlass || "Urlaub",
              start: moment(event.startDate, "DD.MM.YYYY").toDate(),
              end: moment(event.endDate, "DD.MM.YYYY").endOf("day").toDate(),
              status: event.status,
              ...event,
            };
          });
        }
        setFirebaseEvents(formattedData);
      } else {
        setFirebaseEvents([]);
      }
    });
  }, [FID, displayYear, UID, isAdmin, currentMitarbeiter]);

  // Feiertage für das angegebene Jahr berechnen
  const getHolidaysForYear = (year) => {
    const holidays = [];
    let day = moment(`${year}-01-01`);
    const endDate = moment(`${year}-12-31`);
    while (day.isSameOrBefore(endDate, "day")) {
      const holidayName = feiertage(day).isHoliday("HH");
      if (holidayName) {
        holidays.push({
          title: holidayName,
          start: day.toDate(),
          end: day.toDate(),
          allDay: true,
          holiday: true,
        });
      }
      day.add(1, "day");
    }
    return holidays;
  };

  const statusColors = {
    genehmigt: { background: "#28a745", text: "white" },
    wartend: { background: "#ffc107", text: "black" },
    abgelehnt: { background: "#dc3545", text: "white" },
  };

  const anlassColors = {
    Krankheit: { background: "#6f42c1", text: "white" },
    Urlaub: { background: "#007bff", text: "white" },
    Homeoffice: { background: "#17a2b8", text: "white" },
    Dienstreise: { background: "#fd7e14", text: "white" },
    Fortbildung: { background: "#6610f2", text: "white" },
  };

  const holidayEvents = getHolidaysForYear(displayYear);
  // Kombiniere Firebase-Events und Feiertage
  const calendarEvents = [...holidayEvents, ...firebaseEvents];

   

  const eventStyleGetter = (event) => {
    // Feiertage erhalten einen eigenen Stil
    if (event.holiday) {
      return {
        style: {
          backgroundColor: "green",
          border: "none",
          color: "white",
        },
      };
    }
     // Zuerst: nach Status einfärben
     if (event.status && statusColors[event.status]) {
      return {
        style: {
          backgroundColor: statusColors[event.status].background,
          border: "none",
          color: statusColors[event.status].text,
        },
      };
    }
    // Falls kein Status, aber ein definierter Anlass
    if (event.anlass && anlassColors[event.anlass]) {
      return {
        style: {
          backgroundColor: anlassColors[event.anlass].background,
          border: "none",
          color: anlassColors[event.anlass].text,
        },
      };
    }
    // Fallback
    return {
      style: {
        backgroundColor: "gray",
        border: "none",
        color: "white",
      },
    };
  };

  // Rendert einen Monatskalender für den angegebenen Monat
  const renderMonthCalendar = (month) => {
    const date = moment({ year: displayYear, month });
    const monthName = date.format("MMMM");
    return (
      <div
        key={month}
        style={{
          border: "1px solid #ddd",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "10px",
          padding: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {month === 0 && (
            <Button
              variant="secondary"
              onClick={() => navigate("/kalender")}
              style={{ padding: "0.1rem 0.2rem", fontSize: "0.9rem" }}
            >
              Zurück zur Urlaubsverwaltung
            </Button>
          )}
          <h5 style={{ margin: "0", textAlign: "center", flexGrow: 1 }}>
            {monthName} {displayYear}
          </h5>
        </div>
        <Calendar
          localizer={localizer}
          events={calendarEvents}
          date={date.toDate()}
          views={["month"]}
          toolbar={false}
          popup // aktiviert den Popup-Modus ("+X mehr")
          style={{ height: "500px" }}         
          eventPropGetter={eventStyleGetter}
        />
      </div>
    );
  };

  // Rendert die Jahresübersicht als Grid aus 12 Monatskalendern mit Bootstrap
  const renderYearOverview = () => {
    const months = Array.from({ length: 12 }, (_, i) => i);
    return (
      <div>
        <Row className="g-1">
          {months.map((month) => (
            <Col key={month} xs={12} sm={12} md={6} lg={4} xl={3}>
              {renderMonthCalendar(month)}
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  return <div>{renderYearOverview()}</div>;
}
