import React, { useState, useEffect } from "react";
import { ref, getDatabase, query } from "firebase/database";
import { useObjectVal } from "react-firebase-hooks/database";
import { Card, Form, Button, Tabs, Tab, Spinner } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import { useAuth } from "../../../context/authContext";
import GeneralInfoTab from "./GeneralInfoTab";
import ExpiryDatesTab from "./ExpiryDatesTab";
import useWindowDimensions from "../../useMediaQuery";

registerLocale("de", de);

export const FZForm = ({ childToParent, setShowNewFZ, formdata, IsnewFZ }) => {
  const db = getDatabase();
  const { FID } = useAuth();
  //const { width } = useWindowDimensions();
  //const isMobile = width < 768;  

  const [validated, setValidated] = useState(true);
  const [FZid, setFZid] = useState("");

  const [FZdata, setFormData] = useState(
    formdata?.FZ ?? {
      aktiveDate: new Date().getTime(),
      createDate: new Date().getTime(),
      fid: FID,
      isAktiv: true,
      kennzeichen: "",
      islkw: true,
      zuladung: 0,
      antrieb: { value: 1, label: "Diesel" },
      euronorm: { value: 3, label: "Euro 6" },
      isleihfahrzeug: false,
      beschriftung: "",
      kmStand: 0,
      imei: "",
    }
  );

  const [expiryData, setExpiryData] = useState({
    erstzulassung: 0,
    fahrtenschreiber: {
      actualAppointmentDate: 0,
      appointmentDate: 0,
      appointmentMade: false,
      duedateinterval: 700,
      expiryDate: 0,
    },
    fahrtenschreiberauslesen: {
      duedateinterval: 85,
      expiryDate: 1704758400000,
    },
    feuerloescher: {
      actualAppointmentDate: 0,
      appointmentDate: 0,
      appointmentMade: false,
      duedateinterval: 700,
      expiryDate: 0,
    },
    sp: {
      actualAppointmentDate: 0,
      appointmentDate: 0,
      appointmentMade: false,
      duedateinterval: 360,
      expiryDate: 0,
    },
    tuev: {
      actualAppointmentDate: 0,
      appointmentDate: 0,
      appointmentMade: false,
      duedateinterval: 360,
      expiryDate: 0,
    },
  });

  const UMHDRef = query(ref(db, `mhd/` + FID + "/" + formdata.key));
  const [MHDvalues, loadingMHD] = useObjectVal(UMHDRef);

  useEffect(() => {
    if (!loadingMHD && !IsnewFZ && MHDvalues) {
      const sanitizedData = Object.entries(MHDvalues).reduce((acc, [key, val]) => {
        acc[key] = typeof val === "object" ? val : { expiryDate: val };
        return acc;
      }, {});
      setExpiryData(sanitizedData);
    }
  }, [loadingMHD, IsnewFZ, MHDvalues]);

  useEffect(() => {
    if (!IsnewFZ && formdata && formdata.FZ) {
    
      setFZid(formdata.key);
      setFormData(formdata.FZ);
    }else{
      console.log("🚀 FZForm: Empfange Daten für GeneralInfoTab:", formdata.FZ);
    }
  }, [IsnewFZ, formdata]);

  const handleDateChange = (field, date, subField = null) => {
    console.log("🚀 FZForm: field:", field);
    console.log("🚀 FZForm: date:", date);
    console.log("🚀 FZForm: subField:", subField);

    setExpiryData((prevState) => {
      if (field === "erstzulassung") {
        // Direkt als Zahl speichern, da es kein Objekt sein soll
        return {
          ...prevState,
          [field]: date ? date.getTime() : 0,
        };
      } else if (subField) {
        // Sicherstellen, dass das Feld ein Objekt bleibt
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [subField]: date ? date.getTime() : 0,
          },
        };
      } else {
        // Alle anderen Felder bleiben im bestehenden Format
        return {
          ...prevState,
          [field]: date ? date.getTime() : 0,
        };
      }
    });
  };
  
  

  /*const handleDateChange = (field, date, subField = null) => {
    if (subField) {
      setExpiryData((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          [subField]: date ? date.getTime() : 0,
        },
      }));
    } else {
      setExpiryData((prevState) => ({
        ...prevState,
        [field]: date ? date.getTime() : 0,
      }));
    }
  };*/

  if (!loadingMHD) {
    return (
      <>
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => childToParent(e, FZdata, expiryData, FZid)}
          className="mt-3"
        >
          <Card className="align-items-stretch myCard">
            <Card.Header className="text-center">
              {IsnewFZ ? "Allgemeine Angaben über das neue Fahrzeug" : `Fahrzeug ${FZdata.kennzeichen} bearbeiten`}
            </Card.Header>

            <Card.Body className="d-flex flex-column" style={{ overflowY: "auto", flexGrow: 1 }}>
              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="home" title="Allgemeine Angaben">
                  <GeneralInfoTab data={FZdata} setFormData={setFormData} />
                </Tab>

                <Tab eventKey="date" title="Verfalldaten">
                  <ExpiryDatesTab expiryData={expiryData} handleDateChange={handleDateChange} />
                </Tab>
              </Tabs>
            </Card.Body>

            <Card.Footer>
              <div className="row">
                <div className="col d-grid gap-2 mt-auto">
                  <Button variant="outline-danger" onClick={() => setShowNewFZ(false)}>
                    Abbrechen
                  </Button>
                </div>
                <div className="col d-grid gap-2 mt-auto">
                  <Button className="float-right" variant="outline-success" type="submit">
                    {IsnewFZ ? "Speichern" : "Update"}
                  </Button>
                </div>
              </div>
            </Card.Footer>
          </Card>
        </Form>
      </>
    );
  } else {
    return (
      <>
        <div className="home">
          <div className="text-center">
            <Button variant="primary" size="lg" disabled>
              <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true" />
              Loading...
            </Button>
          </div>
        </div>
      </>
    );
  }
};
