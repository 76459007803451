import React, { useState } from 'react';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { useAuth } from '../../../context/authContext';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';

function FileUpload() {
  const [file, setFile] = useState(null);
  const { FID } = useAuth();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = () => {
    if (!file) return toast.error("Bitte Datei auswählen!");

    const storage = getStorage();
    const storageRef = ref(storage, `uploads/${FID}/${file.name}`);

    uploadBytes(storageRef, file).then(() => {
      toast.success("Datei erfolgreich hochgeladen!");
    }).catch(err => {
      toast.error(`Fehler: ${err.message}`);
    });
  };

  return (
    <div>
      <Form.Group>
        <Form.Control type="file" onChange={handleFileChange} />
      </Form.Group>
      <Button onClick={uploadFile}>Hochladen</Button>
    </div>
  );
}

export default FileUpload;
