import { useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react';
import { Card, Tab, Row, Col, Button, ListGroup, Badge, Stack, Container } from 'react-bootstrap';
import { ref, getDatabase, update, set, onValue } from "firebase/database";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { initializeApp } from "firebase/app";
import Switch from "react-switch";
import './mitarbeiterCard.css'
import MForm from "../mitarbeiter/form/form";
import toast from "react-hot-toast";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {useAuth}from "../../../context/authContext"
import {
  Menu,
  Item,
  theme, 
  useContextMenu
} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import useWindowDimensions from "../../useMediaQuery";

const MENU_ID = "menu-id";


export const Mitarbeiter = () => {
  const navigate = useNavigate();
  //const [authuser] = useAuthState(auth);
  const { width } = useWindowDimensions();
  const { currentUser,isAdmin } = useAuth();
  const isMobile = width < 768;
  const db = getDatabase();
  const [UserData, SetUserData] = useState(({
    user: null,
    key: null
  }));
  const [FID, setFID] = useState('');
  const [Aclass, setclass] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [geloeschte, setGeloeschte] = useState(false);
  const [showNewUser, setShowNewUser] = useState(false);
  const [SortUser, setSortUser] = useState([]);
  const {deleteUserbyUID} =  useAuth();
  const { show } = useContextMenu({
    id: MENU_ID
  });

  useEffect(() => {
    if (!isAdmin) {
      navigate(-1)
      
    }
  }, [isAdmin, navigate]);
  function displayMenu(e, props) {
    // console.table(props)
    // pass the item id so the `onClick` on the `Item` has access to it
    show(e, props);
    // show(e, { props: { id: Number(e.currentTarget.id) } });
    // console.table(e)
  }

  function isItemDisabled({ props, data, triggerEvent }) {
    // use the parameters to determine if you want to disable the item or not
    // you get the idea
    return props.isDelete !== true;
  }

  const handleDeleteItemClick = async ({ event, props }) => {

    switch (event.currentTarget.id) {
      case "remove":
        // logic to remove the row
       // console.table(props); // contain to item.id passed by `show`
       try {
          await deleteUserbyUID(props.id);
        
      } catch (error) {
        console.table(error);
      } finally {
        
      }

        break;
      case "share":
        // logic to share
        break;
      case "email":
        //logic to send email
        break;
      case "sponsor":
        //logic to open sponsor page
        break;
      default:
        break;
    }
    //console.table(data );
    // console.table(props );
    // console.table(triggerEvent );
    // console.table(event );

  }
  //const [userValues, userloading, error] = useList(dbRef);

  useEffect(() => {

    const dbRef = ref(db, `users/` + FID);
    return onValue(dbRef, (snap) => {
      if (snap.exists()) {
        const data = snap.val();
        const unsort = Object.keys(data)
          .map(k => ({
            id: k,
            ...data[k]
          }));
        const resual = unsort.filter(user => user.isDelete === geloeschte);
        setSortUser(resual);
        setLoading(false)
        return
      }
      //setLoading(true)

    })
  }, [geloeschte, Loading, db, FID]);


  const [IsnewUser, setIsnewUser] = useState(true);

  const childToParent = (e, data, AZeiten, mhd, userid, passwort) => {  
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      toast.error('Es sind nicht alle Felder korrekt ausgefüllt');
    } else {
      e.preventDefault();

      if (!IsnewUser) { //UpdateUser
        UpdateUser(data, AZeiten, mhd, userid);
      } else {  //NewUser
        SaveNewUser(data, AZeiten, mhd, passwort);

      }

    }

  }

  


  const UpdateUser = (data, AZeiten, mhd, userid) => {   
    if (data.isDelete)
      data.isAktiv = false;

      const arbeitzeiten = AZeiten.map((az) => ({
        arbeitsbeginn: az.active && az.beginn ? az.beginn.getTime() : null,
        arbeitsende: az.active && az.ende ? az.ende.getTime() : null
      }));
      const toastId = toast.loading('Bitte Warten Update...');
      const updates = {};
      updates['/users/' + FID + '/' + userid] = data;
      updates['/arbeitzeiten/' + FID + '/' + userid] = { arbeitzeiten };
      updates['/mhd/' + FID + '/' + userid] = mhd;
    
      update(ref(db), updates).then(() => {
        toast.success (data.name + ' wurde Aktualisiert');
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error('Es ist ein fehler beim Update aufgeteten\n' + errorCode + '\n' + errorMessage);
      }).finally(() => {
        toast.dismiss(toastId);
        setShowNewUser(false);
      });


  }






  const SaveNewUser = (data, AZeiten, mhd, passwort) => {
    const toastId = toast.loading("Bitte Warten Speicher...");
    
 const isFormValid = () => {
    return data.name && data.email && data.telefon && passwort;
  };

  if (!isFormValid()) {
    toast.dismiss(toastId); // Lade-Indikator stoppen
    toast.error("Bitte füllen Sie alle Pflichtfelder aus.");
    return;
  }

    // Lade die Firebase-Konfiguration aus Umgebungsvariablen
    const config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    };

    if (!config.apiKey || !config.authDomain || !config.databaseURL) {
      console.error("Firebase-Konfigurationswerte fehlen. Überprüfen Sie Ihre .env.local-Datei!");
      toast.error("Firebase-Konfigurationswerte fehlen. Überprüfen Sie Ihre .env.local-Datei!");
      return;
    }
  
    const secondaryApp = initializeApp(config, "Secondary");
    const auths = getAuth(secondaryApp);
  
    createUserWithEmailAndPassword(auths, data.email, passwort)
      .then((firebaseUser) => {
        auths.signOut();
        const newuserid = firebaseUser.user.uid;
  
        updateProfile(firebaseUser.user, {
          photoURL: FID,
          displayName: data.name,
        }).then(() => {
          set(ref(db, "users/" + FID + "/" + newuserid), {
            ...data,
          })
            .then(() => {
              set(ref(db, "mhd/" + FID + "/" + newuserid), {
                ...mhd,
              }).then(() => {
                set(ref(db, "arbeitzeiten/" + FID + "/" + newuserid), {
                  AZeiten,
                }).then(() => {
                  setShowNewUser(false);
                });
              });
            });
        });
  
        toast.success(`${data.name} wurde erfolgreich angelegt!`);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(
          `Es ist ein Fehler beim Speichern aufgetreten\n${errorCode}\n${errorMessage}`
        );
      })
      .finally(() => {
        toast.dismiss(toastId);
      });
  };
  



  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser]);





  const handleClickUser = (data, key) => {
  

    setclass(true);
    SetUserData({
      user: data,
      key: key
    })
    setIsnewUser(false);
    setShowNewUser(true);

  };

  
  
  
  const handleClick = (e) => {
    e.preventDefault();
    setclass(true);
    SetUserData({
      user: null,
      key: null
    })
    setIsnewUser(true);
    setShowNewUser(true);

  };

  const handleChange = nextChecked => {
    setShowNewUser(false);
    setGeloeschte(nextChecked);
  };



  
  
    return (
      <>

    {currentUser ?(
      <Container fluid>
  <Row>
    <Col xs={12} sm={12} lg={3} md={4}>
      {!isMobile || !showNewUser ? (
        <Card
          className="mt-3 bg-light align-items-stretch myCard"         
        >
          {/* Fixierter Header mit Hamburger-Menü */}
          <Card.Header
            style={{
              position: "sticky",
              top: 0,
              zIndex: 10,
              backgroundColor: "#f8f9fa",
            }}
            className="d-flex justify-content-between align-middle"
          >
            <div>Mitarbeiter</div>
            <div>
              <Switch
                onChange={handleChange}
                checked={geloeschte}
                onColor="#f0a000"
                offColor="#00a000"
                width={90}
                className="react-switch align-middle text-right"
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 12,
                      color: "black",
                      paddingRight: 20,
                    }}
                  >
                    Aktive
                  </div>
                }
                checkedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 12,
                      color: "black",
                      paddingLeft: 20,
                    }}
                  >
                    Gelöschte
                  </div>
                }
              />
            </div>
          </Card.Header>

          {/* Scrollbarer Card Body */}
          <Card.Body
            className="d-flex flex-column"
            style={{
              overflowY: "auto", // Scrollbar hinzufügen
              flexGrow: 1, // Der Body nimmt den restlichen Platz ein
            }}
          >
            <Tab.Container id="TESTID">
              <Row>
                <Col sm={12}>
                  <ListGroup>
                    <Scrollbars autoHeight autoHeightMax="calc(100vh - 230px)">
                      {SortUser.map((user) => (
                        <ListGroup.Item
                          className="my-2 d-inline-block d-flex justify-content-between align-items-start"
                          key={user.id}
                          id={user.id}
                          onContextMenu={(e) => {
                            displayMenu(e, {
                              props: {
                                id: user.id,
                                isDelete: user.isDelete,
                              },
                            });
                          }}
                          action
                          onClick={() => {
                            handleClickUser(user, user.id);
                          }}
                        >
                          {`${user.name}`}

                          <Stack direction="horizontal" className="ms-auto" gap={3}>
                            {user.isAktiv ? (
                              <Badge bg="success" pill>
                                Aktiv
                              </Badge>
                            ) : (
                              <Badge bg="danger" pill>
                                Deaktiv
                              </Badge>
                            )}
                            {!user.isDelete ? null : (
                              <Badge bg="danger" pill>
                                gelöscht
                              </Badge>
                            )}
                          </Stack>
                        </ListGroup.Item>
                      ))}
                    </Scrollbars>
                  </ListGroup>
                </Col>
              </Row>
            </Tab.Container>
          </Card.Body>

          {/* Fixierter Footer mit Button */}
          <Card.Footer
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "#f8f9fa", // Hintergrundfarbe für visuelle Abgrenzung
              zIndex: 10,
            }}
          >
            <div className="d-grid gap-2">
              <Button variant="outline-success" onClick={handleClick}>
                Neuen Mitarbeiter hinzufügen
              </Button>
            </div>
          </Card.Footer>
        </Card>
      ) : null}
    </Col>

    {showNewUser ? (
      <Col xs={12} sm={12} lg={9} md={8}>
        <MForm
          showNewUser={showNewUser}
          childToParent={childToParent}
          setShowNewUser={setShowNewUser}
          formdata={UserData}
          IsnewUser={IsnewUser}
        />
      </Col>
    ) : null}
  </Row>

  <Menu id={MENU_ID} theme={theme.dark}>
    <Item id="remove" disabled={isItemDisabled} hidden={false} onClick={handleDeleteItemClick}>
      Entgültig Löschen
    </Item>
  </Menu>
</Container>

        ):(
          navigate("/login")
        )}
      </>

    );
 


 

}