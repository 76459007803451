import React from "react";
import { Row, Col, Form, InputGroup, Stack } from "react-bootstrap";
import Select from "react-select";
import Switch from "react-switch";
import "../../../styles/switches.css";

function GeneralInfoTab({ data, setFormData }) {
  const handleChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const optionsAntrieb = [
    { value: 1, label: "Diesel" },
    { value: 2, label: "Benzin" },
    { value: 3, label: "Elektro" },
    { value: 4, label: "Hybrid" },
  ];

  const optionsEuronorm = [
    { value: 1, label: "Euro 4" },
    { value: 2, label: "Euro 5" },
    { value: 3, label: "Euro 6" },
    { value: 4, label: "Euro 6d-TEMP" },
    { value: 5, label: "Ohne" },
  ];

  return (
    <Row className="mb-3">
      <Form.Group as={Col} md="6">
        <Form.Label>Kennzeichen</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Kennzeichen"
          value={data.kennzeichen || ""}
          onChange={(e) => handleChange("kennzeichen", e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Bitte ein Kennzeichen eingeben.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="6">
  <Form.Label>Antriebsart</Form.Label>
  <Select
    options={optionsAntrieb}
    value={Array.isArray(data.antrieb) ? data.antrieb[0] : data.antrieb}
    onChange={(e) => setFormData({ ...data, antrieb: e })}
    menuPortalTarget={document.body}   // ⬅️ Dropdown außerhalb rendern
  />
</Form.Group>

<Form.Group as={Col} md="6">
  <Form.Label>Euro-Norm</Form.Label>
  <Select
    options={optionsEuronorm}
    value={Array.isArray(data.euronorm) ? data.euronorm[0] : data.euronorm}
    onChange={(e) => setFormData({ ...data, euronorm: e })}
    menuPortalTarget={document.body}   // ⬅️ Dropdown außerhalb rendern
  />
</Form.Group>

      <Form.Group as={Col} md="6">
        <Form.Label>KM-Stand</Form.Label>
        <Form.Control
          type="number"
          placeholder="KM Stand"
          required
          value={data.kmStand || ""}
          onChange={(e) => handleChange("kmStand", parseInt(e.target.value) || 0)}
        />
        <Form.Control.Feedback type="invalid">
          Bitte einen gültigen Kilometerstand eingeben.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="6">
  <Stack direction="horizontal" gap={3}>
    <Form.Label>Fahrzeugtyp</Form.Label>
    <Switch
      onChange={(checked) => setFormData({ ...data, islkw: checked })}
      checked={data.islkw}
      className="custom-switch" //Wendet den CSS-Style an          
      width={80}
      height={30}
      handleDiameter={26}
      uncheckedIcon={<div className="switch-text">PKW</div>}
      checkedIcon={<div className="switch-text">LKW</div>}
    />
  </Stack>
</Form.Group>

<Form.Group as={Col} md="6">
  <Stack direction="horizontal" gap={3}>
    <Form.Label>Leihfahrzeug</Form.Label>
    <Switch
      onChange={(checked) => setFormData({ ...data, isleihfahrzeug: checked })}
      checked={data.isleihfahrzeug}
      className="custom-switch"      
      width={80}
      height={30}
      handleDiameter={26}
      uncheckedIcon={<div className="switch-text">Nein</div>}
      checkedIcon={<div className="switch-text">Ja</div>}
    />
  </Stack>
</Form.Group>

<Form.Group as={Col} md="6">
  <Stack direction="horizontal" gap={3}>
    <Form.Label>Aktiv</Form.Label>
    <Switch
      onChange={(checked) => setFormData({ ...data, isAktiv: checked })}
      checked={data.isAktiv}
      className="custom-switch"     
      width={80}
      height={30}
      handleDiameter={26}
      uncheckedIcon={<div className="switch-text">Nein</div>}
      checkedIcon={<div className="switch-text">Ja</div>}
    />
  </Stack>
</Form.Group>
    </Row>
  );
}

export default GeneralInfoTab;
