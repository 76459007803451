import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue, set, get } from "firebase/database";
import { useAuth } from "../../../../context/authContext";

// react-bootstrap
import { Button, Modal, Form } from "react-bootstrap";
// react-hot-toast
import { toast } from "react-hot-toast";

// Wichtig: react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";

// Dein Component:
function TruckCostOverview() {
  const { isAdmin, FID } = useAuth();

  const [vehicles, setVehicles] = useState([]);
  const [KFZvehicles, setKFZVehicles] = useState([]);
  const [fuelings, setFuelings] = useState([]);
  const [vehiclesMerged, setVehiclesMerged] = useState([]);

  // Auswahl / Modal
  const [selectedVehicleId, setSelectedVehicleId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newLicensePlate, setNewLicensePlate] = useState("");
  const [newType, setNewType] = useState("LKW");

  // Kostenfelder
  const [costAnschaffung, setCostAnschaffung] = useState("");
  const [costLaufzeit, setCostLaufzeit] = useState("60");
  const [costSteuern, setCostSteuern] = useState("");
  const [costVersicherung, setCostVersicherung] = useState("");
  const [costKraftstoff, setCostKraftstoff] = useState("");
  const [costWartung, setCostWartung] = useState("");
  const [costMaut, setCostMaut] = useState("");
  const [costPersonal, setCostPersonal] = useState("");
  // Für die Jahres-Filterung bei Tankungen
  const currentYear = new Date().getFullYear();
  const [recordExists, setRecordExists] = useState(false);

  // 1) Tankungen laden + flatten
  useEffect(() => {
    const db = getDatabase();
    const tankungenRef = ref(db, `tankungen/${FID}`);
    onValue(tankungenRef, (snapshot) => {
      if (snapshot.exists()) {
        const outerData = snapshot.val();
        const allFuelings = [];

        // Äußere Schleife: Key = Fahrzeug-ID, Wert = Objekt aller Tank-PushKeys
        for (const [fahrzeugKey, fuelingRecords] of Object.entries(outerData)) {
          //console.log("fuelingRecords:", fuelingRecords);
          // Innere Schleife: Key = PushKey einer Tankung, Wert = Tankdaten
          for (const [autoKey, fuelingData] of Object.entries(fuelingRecords)) {
            allFuelings.push({
              firebaseFzgKey: fahrzeugKey,
              fuelingKey: autoKey,
              ...fuelingData,
            });
          }
        }

        setFuelings(allFuelings);
      } else {
        setFuelings([]);
      }
    });
  }, [FID]);

  // 2) Fahrzeuge (Basisdaten) aus /fahrzeuge/<FID>
  useEffect(() => {
    const db = getDatabase();
    const fahrzeugeRef = ref(db, `fahrzeuge/${FID}`);
    onValue(fahrzeugeRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const arrayData = Object.values(data);
        console.log("Fahrzeuge:", arrayData);
        setVehicles(arrayData);
      } else {
        setVehicles([]);
      }
    });
  }, [FID]);

  useEffect(() => {
    const db = getDatabase();
    const KFZRef = ref(db, `kfz/${FID}`);

    onValue(KFZRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // data = { pushKey1: {...}, pushKey2: {...}, ... }

        // Object.entries => [ [pushKey1, { ... }], [pushKey2, { ... }] ]
        const arrayData = Object.entries(data).map(([key, value]) => {
          // Wir mappen den Firebase-Key (z. B. "-NKcqpz2SKBcx6qzmnSe") auf das Feld "id"
          return { id: key, ...value };
        });

        setKFZVehicles(arrayData);
      } else {
        setKFZVehicles([]);
      }
    });
  }, [FID]);

  // 4) Merging: KFZ-Daten + Tankungen => vehiclesMerged
  useEffect(() => {
    const merged = KFZvehicles.map((veh) => {
      // 1) Filter: Alle Tankungen für dieses Fahrzeug, nur aktuelles Jahr
      const relevantFuelings = fuelings.filter((f) => {
        if (f.fzid !== veh.id) return false;
        const fuelingDate = new Date(f.createdatetime);
        return fuelingDate.getFullYear() === currentYear;
      });

      // 2) SUMME für das laufende Jahr (bisher)
      let totalFuelCostSoFar = 0;
      relevantFuelings.forEach((t) => {
        totalFuelCostSoFar += t.liter * t.preis;
      });

      // 3) Hochrechnung: Tage seit Jahresbeginn
      const today = new Date();
      const startOfYear = new Date(currentYear, 0, 1); // 1. Jan
      const msPerDay = 1000 * 60 * 60 * 24;

      // Wie viele Tage sind schon vergangen?
      const daysGoneBy = (today - startOfYear) / msPerDay;
      // Falls daysGoneBy = 0 (z. B. am 1. Jan), fangen wir Sonderfall ab
      if (daysGoneBy < 1) {
        // Dann setze daysGoneBy auf 1, um Division durch 0 zu vermeiden
        // oder du zeigst 0 an
      }

      // Durchschnittliche Kosten pro Tag
      const costPerDay = totalFuelCostSoFar / daysGoneBy;

      // Jahreshochrechnung
      const yearlyEstimate = costPerDay * 365;

      // Monatsprognose
      const monthlyEstimate = yearlyEstimate / 12;

      // 4) Fahrzeug-Objekt zurückgeben, inkl. Prognosen
      return {
        ...veh,
        // Bisherige Summe (Jan–heute)
        kraftstoffIst: totalFuelCostSoFar,
        // Hochrechnung für's ganze Jahr
        kraftstoffJahr: Math.round(yearlyEstimate),
        // Durchschnitt pro Monat (Jahreshochrechnung / 12)
        kraftstoffMonat: Math.round(monthlyEstimate),
      };
    });

    // 5) Im State ablegen (z. B. vehiclesMerged)
    setVehiclesMerged(merged);
  }, [KFZvehicles, fuelings, currentYear]);

  const currencyFormatter = (cell) => {
    const num = Number(cell) || 0;
    return Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(num);
  };

  // 2) Spaltenkonfiguration für react-bootstrap-table-next
  const columns = [
    {
      dataField: "kennzeichen",
      text: "Kennzeichen",
      headerAlign: "center",
    },
    {
      dataField: "anschaffungMonth", // wir berechnen es gleich in den data
      text: "Anschaffung (Monat)",
      formatter: currencyFormatter,
      headerAlign: "center",
    },
    {
      dataField: "steuern",
      text: "Steuern (Monat)",
      formatter: currencyFormatter,
      headerAlign: "center",
    },
    {
      dataField: "versicherung",
      text: "Versicherung (Monat)",
      formatter: currencyFormatter,
      headerAlign: "center",
    },
    {
      dataField: "kraftstoffMonat",
      text: "Kraftstoff (Monat)",
      formatter: currencyFormatter,
      headerAlign: "center",
    },
    {
      dataField: "wartungReparaturen",
      text: "Wartung/Reparaturen (Monat)",
      formatter: currencyFormatter,
      headerAlign: "center",
    },
    {
      dataField: "maut",
      text: "Maut (Monat)",
      formatter: currencyFormatter,
      headerAlign: "center",
    },
    {
      dataField: "personalkosten",
      text: "Personalkosten (Monat)",
      formatter: currencyFormatter,
      headerAlign: "center",
    },
    {
      dataField: "monthlyTotal",
      text: "Gesamt (Monat)",
      formatter: currencyFormatter,
      headerAlign: "center",
      style: { fontWeight: "bold" },
    },
  ];

  // 3) data für react-bootstrap-table-next erstellen
  //    - wir wollen in "monthlyTotal" die Summe aller relevanten Felder (ohne Kraftstoff Bisher, Hochrechnung)
  //    - wir rechnen Anschaffung / Laufzeit als "anschaffungMonth"
  //    - wir werden auch eine finale "TOTAL"-Zeile anhängen
  let sumAllVehicles = 0; // summiert monthlyTotal aller Fahrzeuge
  let totalAnschaffungMonth = 0;
  let totalSteuern = 0;
  let totalVersicherung = 0;
  let totalKraftstoffMonat = 0;
  let totalWartungReparaturen = 0;
  let totalMaut = 0;
  let totalPersonalkosten = 0;

  const tableData = vehiclesMerged.map((v) => {
    const laufzeit = Number(v.laufzeit) || 1;
    const anschaffungMonth = Number(v.anschaffung) / laufzeit || 0;
    const steuernM = Number(v.steuern) || 0;
    const versichM = Number(v.versicherung) || 0;
    const kraftM = Number(v.kraftstoffMonat) || 0; // aus Hochrechnung?
    const wartungM = Number(v.wartungReparaturen) || 0;
    const mautM = Number(v.maut) || 0;
    const personalM = Number(v.personalkosten) || 0;

    const monthlyTotal =
      anschaffungMonth +
      steuernM +
      versichM +
      kraftM +
      wartungM +
      mautM +
      personalM;

    return {
      id: v.id,
      kennzeichen: v.kennzeichen,
      anschaffungMonth,
      steuern: steuernM,
      versicherung: versichM,
      kraftstoffMonat: kraftM,
      wartungReparaturen: wartungM,
      maut: mautM,
      personalkosten: personalM,
      monthlyTotal,
    };
  });
  // Berechnung der Gesamtwerte pro Kategorie:
  totalAnschaffungMonth = tableData.reduce(
    (sum, v) => sum + Number(v.anschaffungMonth || 0),
    0
  );
  totalSteuern = tableData.reduce((sum, v) => sum + Number(v.steuern || 0), 0);
  totalVersicherung = tableData.reduce(
    (sum, v) => sum + Number(v.versicherung || 0),
    0
  );
  totalKraftstoffMonat = tableData.reduce(
    (sum, v) => sum + Number(v.kraftstoffMonat || 0),
    0
  );
  totalWartungReparaturen = tableData.reduce(
    (sum, v) => sum + Number(v.wartungReparaturen || 0),
    0
  );
  totalMaut = tableData.reduce((sum, v) => sum + Number(v.maut || 0), 0);
  totalPersonalkosten = tableData.reduce(
    (sum, v) => sum + Number(v.personalkosten || 0),
    0
  );
  sumAllVehicles = tableData.reduce(
    (sum, v) => sum + Number(v.monthlyTotal || 0),
    0
  );
  // 4) Jetzt fügen wir eine TOTAL-Zeile hinzu
  //    - hier speichern wir die Summe in "monthlyTotal", und setzen "id": "TOTAL", kennzeichen: "GESAMT"
  // Jetzt fügst du die Zeile mit echten Gesamtwerten hinzu:
  tableData.push({
    id: "TOTAL_ROW",
    kennzeichen: "Gesamt",
    anschaffungMonth: totalAnschaffungMonth,
    steuern: totalSteuern,
    versicherung: totalVersicherung,
    kraftstoffMonat: totalKraftstoffMonat,
    wartungReparaturen: totalWartungReparaturen,
    maut: totalMaut,
    personalkosten: totalPersonalkosten,
    monthlyTotal: sumAllVehicles,
  });

  // ---------- Auswahl/Modal/Erstellen ----------

  // Auswahl im Dropdown
  const handleSelectVehicle = async (e) => {
    const chosenId = e.target.value;
    setSelectedVehicleId(chosenId);
    if (!chosenId) return;

    // Fahrzeug aus /fahrzeuge laden
    const vehicleObj = vehicles.find((v) => v.id === chosenId);
    if (vehicleObj) {
      // Standardmäßig das Kennzeichen aus /fahrzeuge übernehmen
      setNewLicensePlate(vehicleObj.kennzeichen);
      console.log("Selected Vehicle:", vehicleObj.kennzeichen);
    }

    // Prüfen, ob es in KFZvehicles (aus /kfz) bereits einen Datensatz gibt
    const existingRecord = KFZvehicles.find((rec) => rec.id === chosenId);
    if (existingRecord) {
      setRecordExists(true);
      // Falls bereits vorhanden, Werte aus dem vorhandenen Datensatz in das Modal übernehmen:
      setNewLicensePlate(existingRecord.kennzeichen);
      setNewType(existingRecord.typ);
      setCostAnschaffung(existingRecord.anschaffung);
      setCostSteuern(existingRecord.steuern);
      setCostVersicherung(existingRecord.versicherung);
      setCostKraftstoff(existingRecord.kraftstoff);
      setCostWartung(existingRecord.wartungReparaturen);
      setCostMaut(existingRecord.maut);
      setCostPersonal(existingRecord.personalkosten);
    } else {
      setRecordExists(false);
      // Neue Datensatzwerte bleiben wie sie sind bzw. werden aus /fahrzeuge übernommen.
    }
  };

  // Datensatz speichern
  const handleSaveNewVehicle = () => {
    if (!newLicensePlate) {
      toast.error("Bitte ein Kennzeichen eingeben!");
      return;
    }
    const db = getDatabase();
    const fahrzeugRef = ref(db, `kfz/${FID}/${selectedVehicleId}`);

    set(fahrzeugRef, {
      kennzeichen: newLicensePlate,
      typ: newType,
      id: selectedVehicleId,
      anschaffung: costAnschaffung,
      laufzeit: costLaufzeit,
      steuern: costSteuern,
      versicherung: costVersicherung,
      kraftstoff: costKraftstoff,
      wartungReparaturen: costWartung,
      maut: costMaut,
      personalkosten: costPersonal,
    })
      .then(() => {
        toast.success("Neuer Datensatz erfolgreich angelegt!");
        handleCancelModal();
      })
      .catch((err) => {
        toast.error(`Fehler: ${err.message}`);
      });
  };

  // Modal schließen
  const handleCancelModal = () => {
    setShowModal(false);
    setNewLicensePlate("");
    setNewType("LKW");
    setCostAnschaffung("");
    setCostSteuern("");
    setCostVersicherung("");
    setCostKraftstoff("");
    setCostWartung("");
    setCostMaut("");
    setCostPersonal("");
  };

  // ---------- Render ----------

  return (
    <div>
      <h2>KFZ LKW Kostenübersicht</h2>

      {/* Dropdown zur Fahrzeug-Auswahl */}
      <Form.Group className="mb-3" controlId="vehicleSelect">
        <Form.Label>Kennzeichen auswählen:</Form.Label>
        <Form.Select value={selectedVehicleId} onChange={handleSelectVehicle}>
          <option value="">-- bitte wählen --</option>
          {vehicles
            .filter((v) => v.isAktiv)
            .map((v) => (
              <option key={v.id} value={v.id}>
                {v.kennzeichen}
              </option>
            ))}
        </Form.Select>
      </Form.Group>
      {selectedVehicleId && (
        <Button className="mb-3" onClick={() => setShowModal(true)}>
          {recordExists ? "Datensatz bearbeiten" : "Neuen Datensatz anlegen"}
        </Button>
      )}

      {/* React-Bootstrap-Table-Next */}
      <BootstrapTable
        bootstrap4={true}
        bordered={true}
        wrapperClasses="table-responsive"
        noDataIndication="Keine Daten zum Anzeigen."
        keyField="id" // wichtig: Feld, das Einträge eindeutig macht
        data={tableData} // unsere berechneten Zeilen
        columns={columns} // Spaltenkonfiguration
        striped
        hover
        condensed
        rowClasses={(row) => (row.id === "TOTAL_ROW" ? "fw-bold" : "")}
      />

      {/* Modal für die Neuanlage */}
      <Modal show={showModal} onHide={handleCancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>Neuen Datensatz anlegen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Kennzeichen */}
          <Form.Group className="mb-3" style={{ display: "none" }}>
            <Form.Label>Kennzeichen</Form.Label>
            <Form.Control
              type="text"
              value={newLicensePlate}
              disabled
              onChange={(e) => setNewLicensePlate(e.target.value)}
            />
          </Form.Group>

          {/* Typ (LKW, PKW, ...) */}
          <Form.Group className="mb-3">
            <Form.Label>Typ</Form.Label>
            <Form.Select
              value={newType}
              onChange={(e) => setNewType(e.target.value)}
            >
              <option value="LKW">LKW</option>
              <option value="PKW">PKW</option>
            </Form.Select>
          </Form.Group>

          <hr />

          {/* Anschaffung */}
          <Form.Group className="mb-3">
            <Form.Label>Anschaffung</Form.Label>
            <Form.Control
              type="number"
              placeholder="z.B. 800"
              value={costAnschaffung}
              onChange={(e) => setCostAnschaffung(e.target.value)}
            />
          </Form.Group>

          {/* Laufzeit */}
          <Form.Group className="mb-3">
            <Form.Label>Laufzeit</Form.Label>
            <Form.Control
              type="number"
              placeholder="z.B. 36 (Monate)"
              value={costLaufzeit}
              onChange={(e) => setCostLaufzeit(e.target.value)}
            />
          </Form.Group>

          {/* Steuern */}
          <Form.Group className="mb-3">
            <Form.Label>Steuern</Form.Label>
            <Form.Control
              type="number"
              placeholder="z.B. 50"
              value={costSteuern}
              onChange={(e) => setCostSteuern(e.target.value)}
            />
          </Form.Group>

          {/* Versicherung */}
          <Form.Group className="mb-3">
            <Form.Label>Versicherung</Form.Label>
            <Form.Control
              type="number"
              placeholder="z.B. 120"
              value={costVersicherung}
              onChange={(e) => setCostVersicherung(e.target.value)}
            />
          </Form.Group>

          {/* Kraftstoff */}
          <Form.Group className="mb-3" style={{ display: "none" }}>
            <Form.Label>Kraftstoff</Form.Label>
            <Form.Control
              type="number"
              placeholder="Wird berechnet"
              disabled
              value={costKraftstoff}
              onChange={(e) => setCostKraftstoff(e.target.value)}
            />
          </Form.Group>

          {/* Wartung/Reparaturen */}
          <Form.Group className="mb-3">
            <Form.Label>Wartung/Reparaturen</Form.Label>
            <Form.Control
              type="number"
              placeholder="z.B. 300"
              value={costWartung}
              onChange={(e) => setCostWartung(e.target.value)}
            />
          </Form.Group>

          {/* Maut */}
          <Form.Group className="mb-3">
            <Form.Label>Maut</Form.Label>
            <Form.Control
              type="number"
              placeholder="z.B. 200"
              value={costMaut}
              onChange={(e) => setCostMaut(e.target.value)}
            />
          </Form.Group>

          {/* Personalkosten */}
          <Form.Group className="mb-3">
            <Form.Label>Personalkosten</Form.Label>
            <Form.Control
              type="number"
              placeholder="z.B. 2500"
              value={costPersonal}
              onChange={(e) => setCostPersonal(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelModal}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSaveNewVehicle}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TruckCostOverview;
