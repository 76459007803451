import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import { Cube } from "react-preloaders2";
import isEmpty from 'lodash.isempty';
import { 
    Card,
    Tab,
    Row,
    Col,
    Button,
    ListGroup,
    Badge,
    Stack,
    Container,
  } from "react-bootstrap";
  import { ref, getDatabase, onValue } from "firebase/database";
  import AnwesenheitUser from "../anwesenheit/anwesendUser";
  import useWindowDimensions from '../../useMediaQuery.js';




export const Anwesenheit = () => {
    const { currentUser, isAdmin} = useAuth();
    const [FID, setFID] = useState("");
    const navigate = useNavigate();
    const [users , SetUsers] = useState([]);
    const [user , SetUser] = useState([]);
    const [loaduser, SetLoadUser] = useState(true);
    const [fahrzeuge , SetFahrzeuge] = useState([]);
    const [loadfahrzeuge, SetLoadFahrzeuge] = useState(true);
    const [touren , SetTouren] = useState([]);
    const [loadtouren, SetLoadTouren] = useState(true);
    const [isloading, SetIsLoading] = useState(true);
    const { height, width } = useWindowDimensions();
    const isMobile = width < 768;
    const [ShowAnweseheitUser,setShowAnweseheitUser] = useState(false)

    const db = getDatabase();
    const dbRef = ref(getDatabase());

    useEffect(() => {    
      if (!currentUser) return;
      setFID(currentUser.photoURL);
    }, [currentUser]);
    
    useEffect(() => {
      if (!isAdmin) {
        navigate("/dashboard", { replace: true });
      }
    }, [isAdmin, navigate]);

    useEffect(() => {
      if (!FID || !loadtouren) return;
    
      const dbRefTouren = ref(db, `touren/${FID}`);
      return onValue(dbRefTouren, (snap) => {
        SetTouren(snap.exists() ? snap.val() : []);
        SetLoadTouren(false);
      });
    }, [FID, db, loadtouren]);

     
    useEffect(() => {
      if (!FID || !loaduser) return;
    
      const dbRefUsers = ref(db, `users/${FID}`);
      return onValue(dbRefUsers, (snap) => {
        if (snap.exists()) {
          const allUsers = snap.val();
          const activeUsers = Object.entries(allUsers)
            .filter(([_, user]) => user.isAktiv) // ✅ Nur aktive Benutzer
            .reduce((acc, [key, user]) => {
              acc[key] = user;
              return acc;
            }, {});
            
          SetUsers(activeUsers);
        } else {
          SetUsers([]);
        }
        SetLoadUser(false);
      });
    }, [FID]);
    
    

    useEffect(() => {
      if (!FID || !loadfahrzeuge) return;
    
      const dbRefFZ = ref(db, `fahrzeuge/${FID}`);
      return onValue(dbRefFZ, (snap) => {
        SetFahrzeuge(snap.exists() ? snap.val() : []);
        SetLoadFahrzeuge(false);
      });
    }, [FID]);








    useEffect(() => {
      if (!loaduser && !loadfahrzeuge) {
        SetIsLoading(false);
      }
    }, [loaduser, loadfahrzeuge]);
     
    const handleClickUser = (v) => {
      if (!v) return;
      setShowAnweseheitUser(true);
      SetUser(v);
    };
 
    const childToParent = (state) => {
      console.log("childToParent=>",state)
     // ShowAnweseheitUser(state)
    
  
    }

    return (
       <>
      {isloading || loaduser || loadfahrzeuge || loadtouren ? (
  <Cube customLoading={true} time={0} background="#40bfb2" />
) : (
  <Container fluid>
    <Row>
      {!isMobile || !ShowAnweseheitUser ? (
        <Col xs={12} sm={12} lg={3} md={4}>
          <Card className={width < 768 ? 'bg-light align-items-stretch myCardsx mt-3' : 'align-items-stretch myCard mt-3'}>
            <Card.Header className=" d-flex justify-content-between align-middle">
              <div>  </div>
              <div>Mitarbeiter</div>
              <div></div>
            </Card.Header>
            <Card.Body className="d-flex flex-column">
              <Tab.Container id="TESTID">
                <Row>
                  <Col sm={12}>
                    <ListGroup>
                      {users && typeof users === "object" && Object.entries(users).map(([k, v]) => (
                        <ListGroup.Item
                          key={k}
                          className="my-1 d-inline-block d-flex justify-content-between align-items-start"
                          action
                          onClick={() => handleClickUser(v)}
                        >
                          {v.name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                </Row>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      ) : null}

      {!isEmpty(user) && ShowAnweseheitUser ? (
        <Col xs={12} sm={12} lg={9} md={8}>
          <AnwesenheitUser
            User={user}
            Users={users}
            fahrzeuge={fahrzeuge}
            touren={touren}
            childToParent={childToParent}
            setShowAnweseheitUser={setShowAnweseheitUser}
          />
        </Col>
      ) : null}
    </Row>
  </Container>
)}
    </> 
      
    )

}