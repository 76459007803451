import React, { useEffect, useState } from "react";
import {
  Accordion,
  Card,
  ListGroup,
  Badge,
  Button,
  Container,
  Modal,
  Form,
} from "react-bootstrap";
import {
  ref,
  getDatabase,
  onValue,
  set,  
  get,
  remove,
} from "firebase/database";
import moment from "moment";
import toast from "react-hot-toast";
import Feiertage from "moment-feiertage";

export function Urlaubskalender({
  FID,
  year,
  isAdmin,
  UID,
  CurrentMitarbeiter,
}) {
  const db = getDatabase();
  const [urlaubsdaten, setUrlaubsDaten] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editUrlaub, setEditUrlaub] = useState(null);  

  useEffect(() => {
    if (!FID || !year || !UID) return;
    let refPath;
    if (isAdmin) {
      // Für Admins: Alle Urlaube im Jahr laden
      refPath = `kalender/${FID}/${year}`;
    } else {
      // Für Nicht-Admins: Nur die Urlaube des aktuellen Users laden
      refPath = `kalender/${FID}/${year}/${UID}`;
    }
    const urlaubRef = ref(db, refPath);
    

    // 🚀 Urlaubsdaten in Echtzeit laden
    onValue(urlaubRef, (snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        let formattedData;
  
        if (isAdmin) {
          // Admin: Daten sind vermutlich als Objekt von Objekten strukturiert
          formattedData = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
        } else {
          // Nicht-Admin: Hier laden wir nur ein einzelnes Objekt – ggf. als Array verpacken
          formattedData = [{ id: UID, ...data }];
        }
  
        setUrlaubsDaten(formattedData);
      } else {
        setUrlaubsDaten([]);
      }
    });
  }, [FID, db, year, UID, isAdmin]);

  // ✅ Funktion zum Anzeigen von Toasts mit react-hot-toast
  const triggerToast = (message, type) => {
    if (type === "success") {
      toast.success(message);
    } else {
      toast.error(message);
    }
  };

  // ✅ Eintrag löschen (nur für Admins)
  const handleDeleteEntry = async (userID, urlaubID) => {
    if (!isAdmin) return;

    const urlaubRef = ref(db, `kalender/${FID}/${year}/${userID}/${urlaubID}`);
    const urlaubsKontoRef = ref(db, `urlaube/${FID}/${year}/${userID}`);
    const urlaubData = urlaubsdaten.find((u) => u.id === userID)?.[urlaubID];

    if (!urlaubData) {
      console.error("Urlaubseintrag nicht gefunden.");
      return;
    }

    await remove(urlaubRef);

    // 🚀 Urlaubskonto aktualisieren, falls ein genehmigter Urlaub gelöscht wird
    const snapshot = await get(urlaubsKontoRef);
    if (snapshot.exists() && urlaubData.status === "genehmigt") {
      const userData = snapshot.val();
      const neueGenommeneTage = Math.max(
        0,
        (userData.genommeneTage || 0) - urlaubData.anzahltage
      );

      await set(urlaubsKontoRef, {
        ...userData,
        genommeneTage: neueGenommeneTage,
      });
    }

    triggerToast("Eintrag erfolgreich gelöscht!", "success");
  };

  // ✅ Urlaub speichern nach Genehmigung oder Ablehnung
  const handleSaveEdit = async (
    userID,
    urlaubID,
    status,
    kommentar,
    startDate,
    endDate,
    attest
  ) => {
    if (!userID || !urlaubID || !startDate || !endDate) {
      console.error("Fehlende Parameter in handleSaveEdit:", {
        userID,
        urlaubID,
        status,
        startDate,
        endDate,
      });
      return;
    }

    const urlaubRef = ref(db, `kalender/${FID}/${year}/${userID}/${urlaubID}`);
    const urlaubsKontoRef = ref(db, `urlaube/${FID}/${year}/${userID}`);
    const urlaubData = urlaubsdaten.find((u) => u.id === userID)?.[urlaubID];

    if (!urlaubData) {
      console.error("Urlaubseintrag nicht gefunden.");
      return;
    }

    // 🚀 Datumsformatierung auf Deutsch
    const startDateFormatted = moment(startDate, "YYYY-MM-DD").format(
      "DD.MM.YYYY"
    );
    const endDateFormatted = moment(endDate, "YYYY-MM-DD").format("DD.MM.YYYY");

    // 🛠 Berechnung der Arbeitstage
    const anzahltage = calculateWorkdays(startDate, endDate);

    const aktuellerZeitstempel = moment().format("YYYY-MM-DD HH:mm:ss");
    const adminName = CurrentMitarbeiter[UID]?.name || "Unbekannt";

    // 🚀 Krankheitseinträge speichern ohne `status`
    if (urlaubData.anlass === "Krankheit") {
      await set(urlaubRef, {
        ...urlaubData,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        anzahltage,
        attest: attest || "ohne",
        geändertVon: adminName,
        geändertAm: aktuellerZeitstempel,
      });
    } else {
      // 🚀 Normale Urlaube mit `status` speichern
      if (!status) {
        console.error("Fehlender Status für Urlaubsantrag.");
        return;
      }

      await set(urlaubRef, {
        ...urlaubData,
        status,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        anzahltage,
        kommentar: status === "abgelehnt" ? kommentar : urlaubData.kommentar,
        geändertVon: adminName,
        geändertAm: aktuellerZeitstempel,
      });

      // 🚀 Urlaubskonto aktualisieren
      const snapshot = await get(urlaubsKontoRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        let neueBeantragteTage = userData.beantragteTage || 0;
        let neueGenommeneTage = userData.genommeneTage || 0;

        if (status === "genehmigt") {
          neueBeantragteTage = Math.max(0, neueBeantragteTage - anzahltage);
          neueGenommeneTage += anzahltage;
        } else if (status === "abgelehnt") {
          neueBeantragteTage = Math.max(0, neueBeantragteTage - anzahltage);
        }

        await set(urlaubsKontoRef, {
          ...userData,
          beantragteTage: neueBeantragteTage,
          genommeneTage: neueGenommeneTage,
        });
      }
    }

    triggerToast(`Eintrag wurde gespeichert.`, "success");
  };


function calculateWorkdays(startDate, endDate) {
    let sDate = new Date(startDate);
    const eDate = new Date(endDate);
    sDate.setHours(0, 0, 0);
    eDate.setHours(0, 0, 0);
    let mydays = 0;

    while (sDate <= eDate) {
      if (!Feiertage(sDate).isHoliday("HH") && sDate.getDay() > 0 && sDate.getDay() < 6) {
        mydays++;
      }
      sDate.setDate(sDate.getDate() + 1);
    }
    return mydays;
  }



  return (
    <Container>
      <Accordion defaultActiveKey="0">
        {isAdmin
          ? Object.entries(CurrentMitarbeiter).map(
              ([mitarbeiterID, mitarbeiter]) => {
                const mitarbeiterUrlaube = urlaubsdaten.filter(
                  (user) => user.id === mitarbeiterID
                );

                if (mitarbeiterUrlaube.length === 0) return null; // Falls der Mitarbeiter keinen Urlaub hat, auslassen

                return (
                  <Accordion.Item eventKey={mitarbeiterID} key={mitarbeiterID}>
                    <Accordion.Header>
                      <span style={{ fontWeight: "bold" }}>
                        {mitarbeiter.name}
                      </span>
                    </Accordion.Header>

                    <Accordion.Body>
                      <ListGroup variant="flush">
                        {mitarbeiterUrlaube.map((user, userIndex) =>
                          Object.entries(user)
                            .filter(([key]) => key !== "id")
                            .map(([urlaubID, urlaub], index) => {                             

                              const textColor =
                                urlaub.anlass === "Krankheit"
                                  ? "#FFFFFF"
                                  : "#000000"; // Weißer Text für dunklen Hintergrund

                              return (
                                <ListGroup.Item
                                  key={urlaubID}
                                  style={{
                                    backgroundColor:
                                      urlaub.anlass === "Krankheit"
                                        ? "#8B0000"
                                        : "#ffffff",
                                    color: textColor,
                                    borderRadius: "10px",
                                    marginBottom: "5px",
                                    padding: "10px",
                                  }}
                                >
                                  <strong>{urlaub.anlass}</strong> (
                                  {urlaub.startDate} - {urlaub.endDate}){" "}
                                  {urlaub.anzahltage} Tage{" "}
                                  <Badge
                                    bg={
                                      urlaub.anlass === "Krankheit"
                                        ? "danger" // Krankheit wird immer rot angezeigt
                                        : urlaub.status === "genehmigt"
                                        ? "success"
                                        : urlaub.status === "wartend"
                                        ? "warning"
                                        : "danger"
                                    }
                                  >
                                    {urlaub.anlass === "Krankheit"
                                      ? `Krankheit (${
                                          urlaub.attest === "mit"
                                            ? "Mit Attest"
                                            : "Ohne Attest"
                                        })`
                                      : urlaub.status}
                                  </Badge>
                                  <ListGroup variant="flush">
                                    <ListGroup.Item>
                                      <strong>Von:</strong> {urlaub.startDate}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                      <strong>Bis:</strong> {urlaub.endDate}
                                    </ListGroup.Item>
                                    {urlaub.kommentar && (
                                      <ListGroup.Item>
                                        <strong>Grund:</strong>{" "}
                                        {urlaub.kommentar}
                                      </ListGroup.Item>
                                    )}

                                    {isAdmin && (
                                      <>
                                        <ListGroup.Item>
                                          <Button
                                            variant="warning"
                                            onClick={() => {
                                              setEditUrlaub({
                                                userID: user.id,
                                                urlaubID,
                                                userName: mitarbeiter.name,
                                                ...urlaub,
                                              });
                                              setShowModal(true);
                                            }}
                                          >
                                            Bearbeiten
                                          </Button>
                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                          <Button
                                            variant="danger"
                                            onClick={() =>
                                              handleDeleteEntry(
                                                user.id,
                                                urlaubID
                                              )
                                            }
                                          >
                                            Eintrag löschen
                                          </Button>
                                        </ListGroup.Item>
                                      </>
                                    )}
                                  </ListGroup>
                                </ListGroup.Item>
                              );
                            })
                        )}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              }
            )
          : // Falls der Benutzer kein Admin ist, nur seine eigenen Urlaube anzeigen
            urlaubsdaten.map((user, userIndex) =>
              Object.entries(user)
                .filter(([key]) => key !== "id")
                .map(([urlaubID, urlaub], index) => {
                  const statusColors = {
                    genehmigt: "#198754",
                    wartend: "#ffc107",
                    abgelehnt: "#dc3545",
                    krankheit: "#8B0000", // Dunkelrot für Krankheitseinträge
                  };

                  const textColor =
                    urlaub.anlass === "Krankheit" ? "#FFFFFF" : "#000000";

                  return (
                    <Accordion.Item
                      eventKey={`${userIndex}-${index}`}
                      key={urlaubID}
                    >
                      <Accordion.Header
                        style={{
                          backgroundColor:
                            statusColors[urlaub.status] || "#f8f9fa",
                          color: "white",
                          padding: "10px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <span style={{ flexGrow: 1 }}>
                          {urlaub.anlass} ({urlaub.startDate} - {urlaub.endDate}
                          ) {urlaub.anzahltage} Tage
                        </span>
                      </Accordion.Header>

                      <Accordion.Body
                        style={{
                          backgroundColor:
                            urlaub.anlass === "Krankheit"
                              ? "#8B0000"
                              : "#ffffff",
                          color: textColor,
                        }}
                      >
                        <Card>
                          <Card.Body>
                            <ListGroup variant="flush">
                              <ListGroup.Item>
                                <strong>Von:</strong> {urlaub.startDate}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <strong>Bis:</strong> {urlaub.endDate}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <strong>Status:</strong>
                                <Badge
                                  bg={
                                    urlaub.status === "genehmigt"
                                      ? "success"
                                      : urlaub.status === "wartend"
                                      ? "warning"
                                      : "danger"
                                  }
                                >
                                  {urlaub.status}
                                </Badge>
                              </ListGroup.Item>
                            </ListGroup>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })
            )}
      </Accordion>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Urlaub bearbeiten - {editUrlaub?.userName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editUrlaub && (
            <Form>
              <Form.Group>
                <Form.Label>Anlass</Form.Label>
                <Form.Control
                  type="text"
                  value={editUrlaub.anlass || ""}
                  onChange={(e) =>
                    setEditUrlaub((prev) => ({
                      ...prev,
                      anlass: e.target.value,
                    }))
                  }
                  disabled
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Startdatum</Form.Label>
                <Form.Control
                  type="date"
                  value={
                    editUrlaub.startDate
                      ? moment(editUrlaub.startDate, [
                          "YYYY-MM-DD",
                          "DD.MM.YYYY",
                        ]).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) =>
                    setEditUrlaub((prev) => ({
                      ...prev,
                      startDate: e.target.value,
                    }))
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Enddatum</Form.Label>
                <Form.Control
                  type="date"
                  value={
                    editUrlaub.endDate
                      ? moment(editUrlaub.endDate, [
                          "YYYY-MM-DD",
                          "DD.MM.YYYY",
                        ]).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) =>
                    setEditUrlaub((prev) => ({
                      ...prev,
                      endDate: e.target.value,
                    }))
                  }
                />
              </Form.Group>

              {/* 🆕 Status nur anzeigen, wenn es sich NICHT um eine Krankheit handelt */}
              {editUrlaub.anlass !== "Krankheit" && (
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={editUrlaub.status || "wartend"}
                    onChange={(e) =>
                      setEditUrlaub((prev) => ({
                        ...prev,
                        status: e.target.value,
                      }))
                    }
                  >
                    <option value="wartend">Wartend</option>
                    <option value="genehmigt">Genehmigt</option>
                    <option value="abgelehnt">Abgelehnt</option>
                  </Form.Select>
                </Form.Group>
              )}

              {/* 🆕 Attest-Auswahl nur anzeigen, wenn es eine Krankheit ist */}
              {editUrlaub.anlass === "Krankheit" && (
                <Form.Group>
                  <Form.Label>Attest</Form.Label>
                  <Form.Select
                    value={editUrlaub.attest || "ohne"}
                    onChange={(e) =>
                      setEditUrlaub((prev) => ({
                        ...prev,
                        attest: e.target.value,
                      }))
                    }
                  >
                    <option value="ohne">Ohne Attest</option>
                    <option value="mit">Mit Attest</option>
                  </Form.Select>
                </Form.Group>
              )}

              {editUrlaub.status === "abgelehnt" && (
                <Form.Group>
                  <Form.Label>Ablehnungsgrund</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Gib den Grund für die Ablehnung an"
                    value={editUrlaub.kommentar || ""}
                    onChange={(e) =>
                      setEditUrlaub((prev) => ({
                        ...prev,
                        kommentar: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
              )}
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Schließen
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (!editUrlaub) return;
              handleSaveEdit(
                editUrlaub.userID,
                editUrlaub.urlaubID,
                editUrlaub.anlass === "Krankheit"
                  ? undefined
                  : editUrlaub.status, // 🆕 Kein `status` für Krankheit
                editUrlaub.kommentar,
                moment(editUrlaub.startDate, [
                  "YYYY-MM-DD",
                  "DD.MM.YYYY",
                ]).format("YYYY-MM-DD"),
                moment(editUrlaub.endDate, ["YYYY-MM-DD", "DD.MM.YYYY"]).format(
                  "YYYY-MM-DD"
                ),
                editUrlaub.anlass === "Krankheit"
                  ? editUrlaub.attest
                  : undefined
              );
              setShowModal(false);
            }}
          >
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
