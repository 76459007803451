import { useState, useEffect } from "react";
import { ref, onValue, getDatabase } from "firebase/database";

/**
 * Diese Hilfsfunktion bekommt das Jahres-Objekt (für entweder "mitarbeiter" oder "tour")
 * und packt alles in ein gemeinsames Objekt { key => { timestamp => dayData } }.
 *
 * yearData hat z.B. die Struktur:
 * {
 *   "1": {  // Monat 1 => Januar
 *     "someUserOrTour": {
 *       "1672531200000": { ...DayData... },
 *       ...
 *     },
 *     ...
 *   },
 *   "2": { // Monat 2 => Februar
 *     ...
 *   },
 *   ...
 * }
 */
function parseYearData(yearData) {
  const result = {};

  // yearData: { monthIndex(1..12): { userOrTourId: { timestamp: dayData } } }
  Object.entries(yearData).forEach(([monthStr, idObj]) => {
    // monthStr z.B. "1" (Januar), idObj = { userOrTourId => { timestamp => dayData } }
    Object.entries(idObj).forEach(([theId, timesObj]) => {
      if (!result[theId]) {
        result[theId] = {};
      }
      // timesObj = { "1672531200000": dayData, ...}
      Object.entries(timesObj).forEach(([timestamp, dayData]) => {
        // Speichere das in result[theId][timestamp]
        result[theId][timestamp] = dayData;
      });
    });
  });

  return result;
}

/**
 * Diese Funktion erzeugt eine Monatliche Zusammenfassung (0..11) für das gegebene Daten-Objekt,
 * in dem wir sämtliche Tagesdatensätze pro Key (userOrTourId) aufsummieren.
 *
 * data = { userOrTourId: { timestamp => dayData } }
 */
function getYearlyMonthlySummaries(data, targetYear) {
  const monthlySummaries = [];

  // Schleife über alle Monate 0..11 (im Date-Objekt)
  for (let month = 0; month < 12; month++) {
    const userSummaries = [];

    Object.entries(data).forEach(([userKey, dailyRecords]) => {
      let totalTagessaldoFP = 0;
      let totalTagessaldoGP = 0;
      let totalLeergutAbholungFP = 0;
      let totalLeergutAbholungGP = 0;
      let totalUebernahmeFP = 0;
      let totalUebernahmeGP = 0;
      let totalLeergutrueckgabeFP = 0;
      let totalLeergutrueckgabeGP = 0;

      // Durch alle Tagesdatensätze iterieren
      Object.entries(dailyRecords).forEach(([timestamp, dayData]) => {
        const date = new Date(Number(timestamp));
        if (date.getFullYear() === targetYear && date.getMonth() === month) {
          totalTagessaldoFP += dayData?.tagessaldo?.fp || 0;
          totalTagessaldoGP += dayData?.tagessaldo?.gp || 0;

          totalLeergutAbholungFP += dayData?.leergutabholung?.fp || 0;
          totalLeergutAbholungGP += dayData?.leergutabholung?.gp || 0;

          totalUebernahmeFP += dayData?.uebernahme?.fp || 0;
          totalUebernahmeGP += dayData?.uebernahme?.gp || 0;

          totalLeergutrueckgabeFP += dayData?.leergutrueckgabe?.fp || 0;
          totalLeergutrueckgabeGP += dayData?.leergutrueckgabe?.gp || 0;
        }
      });

      userSummaries.push({
        name: userKey,
        totalTagessaldoFP,
        totalTagessaldoGP,
        totalLeergutAbholungFP,
        totalLeergutAbholungGP,
        totalUebernahmeFP,
        totalUebernahmeGP,
        totalLeergutrueckgabeFP,
        totalLeergutrueckgabeGP,
        diffFP: totalTagessaldoFP - totalLeergutAbholungFP,
        diffGP: totalTagessaldoGP - totalLeergutAbholungGP,
      });
    });

    monthlySummaries.push({
      month, // 0 = Januar, 1 = Februar, etc.
      summaries: userSummaries,
    });
  }

  return monthlySummaries;
}

const usePalettenKontoData = (fid, targetYear = new Date().getFullYear()) => {
  const [summaries, setSummaries] = useState([]);
  const [monthlySummaries, setMonthlySummaries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!fid) return; // Warte, bis die FID vorliegt

    const db = getDatabase();
    // Wir lesen in einem Rutsch die gesamte Struktur: palettenkonto/
    // Dort erwarten wir:
    // {
    //   mitarbeiter: {
    //     [year]: { [month]: { [userId]: { [timestamp]: dayData }}}
    //   },
    //   tour: {
    //     [year]: { [month]: { [tourName]: { [timestamp]: dayData }}}
    //   }
    // }
    const palettenRef = ref(db, `palettenkonto/${fid}`);
    setIsLoading(true);

    const unsubscribe = onValue(
      palettenRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          setSummaries([]);
          setMonthlySummaries([]);
          setError("Keine Daten gefunden.");
          setIsLoading(false);
          return;
        }

        const rootData = snapshot.val(); // { mitarbeiter: {...}, tour: {...} }
       
        // 1) Die Daten pro Jahr für Mitarbeiter und Tour extrahieren
        const mitarbeiterYearData = rootData.mitarbeiter?.[targetYear] || {};
        const tourYearData = rootData.tour?.[targetYear] || {};

        // 2) Parsed: Umwandeln in { key => {timestamp => dayData} }
        const parsedMitarbeiter = parseYearData(mitarbeiterYearData);
        const parsedTour = parseYearData(tourYearData);

        // 3) Für summaries (Gesamtübersicht) wollen wir ALLE (Mitarbeiter+Tour) zusammen
        const combinedData = {
          ...parsedMitarbeiter,
          ...parsedTour,
        };

        // (A) Gesamtsummen (über alle Tage) für ALLE
        const newSummaries = [];
        Object.entries(combinedData).forEach(([key, timeObj]) => {
          let totalTagessaldoFP = 0;
          let totalTagessaldoGP = 0;
          let totalLeergutAbholungFP = 0;
          let totalLeergutAbholungGP = 0;
          let totalUebernahmeFP = 0;
          let totalUebernahmeGP = 0;
          let totalLeergutrueckgabeFP = 0;
          let totalLeergutrueckgabeGP = 0;

          Object.values(timeObj).forEach((day) => {
            totalTagessaldoFP += day?.tagessaldo?.fp || 0;
            totalTagessaldoGP += day?.tagessaldo?.gp || 0;

            totalLeergutAbholungFP += day?.leergutabholung?.fp || 0;
            totalLeergutAbholungGP += day?.leergutabholung?.gp || 0;

            totalUebernahmeFP += day?.uebernahme?.fp || 0;
            totalUebernahmeGP += day?.uebernahme?.gp || 0;

            totalLeergutrueckgabeFP += day?.leergutrueckgabe?.fp || 0;
            totalLeergutrueckgabeGP += day?.leergutrueckgabe?.gp || 0;
          });

          newSummaries.push({
            name: key,
            totalTagessaldoFP,
            totalTagessaldoGP,
            totalLeergutAbholungFP,
            totalLeergutAbholungGP,
            totalUebernahmeFP,
            totalUebernahmeGP,
            totalLeergutrueckgabeFP,
            totalLeergutrueckgabeGP,
            diffFP: totalTagessaldoFP - totalLeergutAbholungFP,
            diffGP: totalTagessaldoGP - totalLeergutAbholungGP,
          });
        });
        setSummaries(newSummaries);

        // (B) Für monthlySummaries wollen wir NUR Tour-Daten
        //     => wir übergeben nur parsedTour an getYearlyMonthlySummaries(...)
        const newMonthlySummaries = getYearlyMonthlySummaries(parsedTour, targetYear);
        setMonthlySummaries(newMonthlySummaries);

        setIsLoading(false);
      },
      (err) => {
        setError(err.message);
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [fid, targetYear]);

  return { summaries, monthlySummaries, isLoading, error };
};

export default usePalettenKontoData;
