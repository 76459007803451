import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Alert, Container, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ref, getDatabase, update, onValue, remove, push } from "firebase/database";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const TankungenDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { FID, fzid } = location.state || {};

  const [currentTankungen, setCurrentTankungen] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allFahrzeuge, setAllFahrzeuge] = useState([]);
  const [allTouren, setAllTouren] = useState([]);

  const [editTankung, setEditTankung] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const db = getDatabase();

  // Fahrzeuge laden
  useEffect(() => {
    if (FID) {
      const fahrzeugeRef = ref(db, `fahrzeuge/${FID}`);
      onValue(fahrzeugeRef, (snap) => {
        if (snap.exists()) {
          setAllFahrzeuge(Object.values(snap.val()).filter((f) => f.isAktiv));
        }
      });
    }
  }, [FID, db]);

  // Nutzer laden
  useEffect(() => {
    if (FID) {
      const usersRef = ref(db, `users/${FID}`);
      onValue(usersRef, (snap) => {
        if (snap.exists()) {
          setAllUsers(Object.values(snap.val()).filter((u) => u.isAktiv));
        }
      });
    }
  }, [FID, db]);

  // Touren laden
  useEffect(() => {
    if (FID) {
      const tourenRef = ref(db, `touren/${FID}`);
      onValue(tourenRef, (snap) => {
        if (snap.exists()) {
          setAllTouren(Object.values(snap.val()).filter((t) => t.isAktiv));
        }
      });
    }
  }, [FID, db]);

  // Tankungen laden
  useEffect(() => {
    if (FID && fzid) {
      const tankungenRef = ref(db, `tankungen/${FID}/${fzid}`);
      onValue(tankungenRef, (snap) => {
        if (snap.exists()) {
          setCurrentTankungen(
            Object.entries(snap.val()).map(([id, value]) => ({
              id,
              ...value,
            }))
          );
        }
      });
    }
  }, [FID, fzid, db]);

  // Bearbeitungs-Modus starten
  const handleEdit = (tankung) => {
    setEditTankung(tankung);
    setShowModal(true);
  };

  // Neues Element erstellen: Modal mit leeren Standardwerten öffnen
  const handleCreate = () => {
    const newTankung = {
      createdatetime: new Date().getTime(),
      liter: 0,
      preis: 0,
      kmstand: 0,
      verbrauch: 0,
      distanz: 0,
      sorte: "",
      userid: "",
      tourid: ""
    };
    setEditTankung(newTankung);
    setShowModal(true);
  };

  // Tankung speichern (aktualisieren oder neu anlegen)
  const handleSave = async () => {
    if (!editTankung) return;
    if (editTankung.id) {
      // Update eines bestehenden Eintrags
      const tankungRef = ref(db, `tankungen/${FID}/${fzid}/${editTankung.id}`);
      try {
        await update(tankungRef, editTankung);
        setSuccess("Tankung erfolgreich aktualisiert.");
        setShowModal(false);
      } catch (err) {
        setError("Fehler beim Speichern der Tankung.");
      }
    } else {
      // Neuer Datensatz
      const tankungenRef = ref(db, `tankungen/${FID}/${fzid}`);
      try {
        const newRef = push(tankungenRef);
        await update(newRef, editTankung);
        setSuccess("Tankung erfolgreich erstellt.");
        setShowModal(false);
      } catch (err) {
        setError("Fehler beim Erstellen der Tankung.");
      }
    }
  };

  // Tankung löschen
  const handleDelete = async (tankungId) => {
    const confirmed = window.confirm("Möchten Sie diese Tankung wirklich löschen?");
    if (!confirmed) return;

    const tankungRef = ref(db, `tankungen/${FID}/${fzid}/${tankungId}`);
    try {
      await remove(tankungRef);
      setSuccess("Tankung erfolgreich gelöscht.");
    } catch (err) {
      setError("Fehler beim Löschen der Tankung.");
    }
  };

  // Spalten für react-bootstrap-table2
  const columns = [
    {
      dataField: "createdatetime",
      text: "Datum",
      sort: true,
      formatter: (cell) =>
        cell ? new Date(cell).toLocaleString("de-DE") : ""
    },
    {
      dataField: "liter",
      text: "Liter",
      sort: true
    },
    {
      dataField: "preis",
      text: "Preis",
      sort: true
    },
    {
      dataField: "kmstand",
      text: "KM-Stand",
      sort: true
    },
    {
      dataField: "verbrauch",
      text: "Verbrauch",
      sort: true,
      formatter: (cell) =>
        typeof cell === "number" ? cell.toFixed(2) : ""
    },
    {
      dataField: "distanz",
      text: "Distanz",
      sort: true
    },
    {
      dataField: "sorte",
      text: "Betriebsstoff",
      sort: true
    },
    {
      dataField: "userid",
      text: "Fahrer",
      sort: true,
      formatter: (cell) => {
        const user = allUsers.find((u) => u.id === cell);
        return user ? user.name : "Unbekannt";
      }
    },
    {
      dataField: "tourid",
      text: "Tour",
      sort: true,
      formatter: (cell) => {
        const tour = allTouren.find((t) => t.id === cell);
        return tour ? tour.bezeichnung : "Unbekannt";
      }
    },
    {
      dataField: "actions",
      text: "Aktionen",
      formatter: (cell, row) => (
        <>
          <Button
            variant="primary"
            size="sm"
            className="me-2"
            onClick={() => handleEdit(row)}
          >
            Bearbeiten
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleDelete(row.id)}
          >
            Löschen
          </Button>
        </>
      )
    }
  ];

  return (
    <Container fluid>
      <Button className="mt-3" variant="primary" onClick={() => navigate(-1)}>
        Zurück
      </Button>
      <Button className="mt-3 ms-2" variant="success" onClick={handleCreate}>
        Neue Tankung hinzufügen
      </Button>
      <h3 className="text-center my-4">
        Detaillierte Übersicht der Tankungen
      </h3>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <BootstrapTable
        keyField="id"
        data={currentTankungen}
        columns={columns}
        pagination={paginationFactory()}
        striped
        hover
        condensed
        wrapperClasses="table-responsive"
      />

      {/* Modal zum Bearbeiten/Erstellen der Tankung */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editTankung && editTankung.id
              ? "Tankung bearbeiten"
              : "Neue Tankung erstellen"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editTankung && (
            <Form>
              {/* Datum */}
              <Form.Group className="mb-3">
                <Form.Label>Datum</Form.Label>
                <DatePicker
                  selected={
                    editTankung.createdatetime
                      ? new Date(editTankung.createdatetime)
                      : null
                  }
                  onChange={(date) =>
                    setEditTankung({
                      ...editTankung,
                      createdatetime: date ? date.getTime() : null,
                    })
                  }
                  dateFormat="dd.MM.yyyy HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  className="form-control"
                  placeholderText="Datum auswählen"
                />
              </Form.Group>

              {/* Liter */}
              <Form.Group className="mb-3">
                <Form.Label>Liter</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  value={editTankung.liter || ""}
                  onChange={(e) => {
                    const normalized = e.target.value.replace(",", ".");
                    setEditTankung({
                      ...editTankung,
                      liter: parseFloat(normalized) || 0,
                    });
                  }}
                />
              </Form.Group>

              {/* Preis */}
              <Form.Group className="mb-3">
                <Form.Label>Preis</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  value={editTankung.preis || ""}
                  onChange={(e) =>
                    setEditTankung({
                      ...editTankung,
                      preis: parseFloat(e.target.value) || 0,
                    })
                  }
                />
              </Form.Group>

              {/* KM-Stand */}
              <Form.Group className="mb-3">
                <Form.Label>KM-Stand</Form.Label>
                <Form.Control
                  type="number"
                  value={editTankung.kmstand || ""}
                  onChange={(e) =>
                    setEditTankung({
                      ...editTankung,
                      kmstand: parseInt(e.target.value, 10) || 0,
                    })
                  }
                />
              </Form.Group>

              {/* Verbrauch */}
              <Form.Group className="mb-3">
                <Form.Label>Verbrauch (berechnet oder manuell)</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  value={editTankung.verbrauch || ""}
                  onChange={(e) =>
                    setEditTankung({
                      ...editTankung,
                      verbrauch: parseFloat(e.target.value) || 0,
                    })
                  }
                />
              </Form.Group>

              {/* Distanz */}
              <Form.Group className="mb-3">
                <Form.Label>Distanz</Form.Label>
                <Form.Control
                  type="number"
                  value={editTankung.distanz || ""}
                  onChange={(e) =>
                    setEditTankung({
                      ...editTankung,
                      distanz: parseInt(e.target.value, 10) || 0,
                    })
                  }
                />
              </Form.Group>

              {/* Betriebsstoff (Select: Diesel oder AdBlue) */}
              <Form.Group className="mb-3">
                <Form.Label>Betriebsstoff</Form.Label>
                <Form.Select
                  value={editTankung.sorte || ""}
                  onChange={(e) =>
                    setEditTankung({
                      ...editTankung,
                      sorte: e.target.value,
                    })
                  }
                >
                  <option value="">-- Bitte wählen --</option>
                  <option value="Diesel">Diesel</option>
                  <option value="AdBlue">AdBlue</option>
                </Form.Select>
              </Form.Group>

              {/* Fahrer */}
              <Form.Group className="mb-3">
                <Form.Label>Fahrer</Form.Label>
                <Form.Select
                  value={editTankung.userid || ""}
                  onChange={(e) =>
                    setEditTankung({
                      ...editTankung,
                      userid: e.target.value,
                    })
                  }
                >
                  <option value="">-- Bitte wählen --</option>
                  {allUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              {/* Tour */}
              <Form.Group className="mb-3">
                <Form.Label>Tour</Form.Label>
                <Form.Select
                  value={editTankung.tourid || ""}
                  onChange={(e) =>
                    setEditTankung({
                      ...editTankung,
                      tourid: e.target.value,
                    })
                  }
                >
                  <option value="">-- Bitte wählen --</option>
                  {allTouren.map((tour) => (
                    <option key={tour.id} value={tour.id}>
                      {tour.bezeichnung}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Abbrechen
          </Button>
          <Button variant="success" onClick={handleSave}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default TankungenDetail;
