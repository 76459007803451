import React, { useState } from "react";
import { useAuth } from "../../../context/authContext";
import { Urlaubskalender } from "./Urlaubskalender";
import { UrlaubInfo } from "./UrlaubInfo";
import { UrlaubBeantragen } from "./UrlaubBeantragen";
import { KrankheitEintragen } from "./KrankheitEintragen";
import { Container, Form, Row, Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Kalender() {
  const { FID, UID, isAdmin, currentUserDetail, currentMitarbeiter } = useAuth();
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  return (
    <Container fluid className="my-4">
      <Card className="shadow-sm">
        <Card.Header className="bg-primary text-white text-center">
          <h1 className="m-0">Urlaubskalender</h1>
        </Card.Header>
        <Card.Body>
          {/* Erste Zeile: Jahr auswählen & Urlaub beantragen */}
          <Row className="align-items-end mb-4">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Jahr auswählen</Form.Label>
                <Form.Select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(Number(e.target.value))}
                >
                  {[...Array(5)].map((_, i) => {
                    const year = currentYear - 4 + i;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <UrlaubBeantragen
                FID={FID}
                year={selectedYear}
                UID={UID}
                CurrentUser={currentUserDetail}
              />
            </Col>
            {isAdmin && (
              <Col xs={12} md={4} className="text-center">                 
                  <KrankheitEintragen
                    FID={FID}
                    year={selectedYear}
                    CurrentMitarbeiter={currentMitarbeiter}
                  />
                 </Col>
              )}
          </Row>

          {/* Zweite Zeile: UrlaubInfo & Krankheit eintragen / Urlaubsübersicht */}
          <Row className="mb-4">
            <Col xs={12} md={6}>
              <h2 className="text-center mb-3">UrlaubInfo</h2>
             
                <UrlaubInfo
                  FID={FID}
                  year={selectedYear}
                  isAdmin={isAdmin}
                  UID={UID}
                  CurrentUser={currentUserDetail}
                  CurrentMitarbeiter={currentMitarbeiter}
                />       
              
            </Col>
            <Col xs={12} md={6}>
              <h2 className="text-center mb-3">Urlaubsübersicht</h2>
              <Urlaubskalender
                FID={FID}
                year={selectedYear}
                isAdmin={isAdmin}
                UID={UID}
                CurrentMitarbeiter={currentMitarbeiter}
              />
            </Col>
          </Row>

          {/* Dritte Zeile: Navigation zu Monats- und Jahresansicht */}
          <Row className="text-center">
            <Col xs={12} md={6} className="mb-3">
              <h2 className="mb-3">Monatsansicht</h2>
              <Button
                variant="primary"
                as={Link}
                to={`/monatsansicht`}
                state={{ year: selectedYear, FID, UID, isAdmin, currentMitarbeiter }}
                className="w-100"
              >
                Monatsansicht anzeigen
              </Button>
            </Col>
            <Col xs={12} md={6} className="mb-3">
              <h2 className="mb-3">Jahresansicht</h2>
              <Button
                variant="primary"
                as={Link}
                to={`/jahresansicht`}
                state={{ year: selectedYear, FID, UID, isAdmin, currentMitarbeiter }}
                className="w-100"
              >
                Jahresansicht anzeigen
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
