import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { useAuth } from "../../../context/authContext";
import usePalettenKontoData from "../palettenkonto/usePalettenKontoData";
import { Card, Container, Tabs, Tab, Form, Button, Modal, Table } from "react-bootstrap";

const PalettenKonto = () => {
  const { FID, currentMitarbeiter } = useAuth();
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  // Deine Daten-Hook, die monthlySummaries (nur Touren) und summaries (Mitarbeiter+Touren) liefert
  const { monthlySummaries, summaries, isLoading, error } = usePalettenKontoData(FID, selectedYear);

  // Startwerte (pro Tour, pro Monat(0..11) => {fp, gp})
  const [startValuesByTour, setStartValuesByTour] = useState({});

  // ---------------------------
  //  MODAL-LOGIK
  // ---------------------------
  const [showModal, setShowModal] = useState(false);

  // Monat, den der User wählt, 1-basiert (1 = Januar, ... 12 = Dezember)
  const [modalMonth, setModalMonth] = useState(1);

  // Modal öffnen
  const handleOpenModal = () => {
    setModalMonth(1); // Default: Januar
    setShowModal(true);
  };
  // Modal schließen
  const handleCloseModal = () => setShowModal(false);

  // Laden der Startwerte aus Firebase
  useEffect(() => {
    if (!FID) return;
    const db = getDatabase();
    const baseRef = ref(db, `palettenkonto/${FID}/startwerte/${selectedYear}`);
   
    const unsubscribe = onValue(baseRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val(); // { "TourName": { "0": {fp, gp}, "1": {...}, ... }, "987": {...} }
        const loaded = {};
        
        Object.entries(data).forEach(([tourName, monthsObj]) => {
          const arr = Array.from({ length: 12 }, (_, i) => {
            
            if (monthsObj[i]) {
              return {
                fp: monthsObj[i].fp || 0,
                gp: monthsObj[i].gp || 0,
              };
            }
            return { fp: 0, gp: 0 };
          });
          loaded[tourName] = arr;
        });
        
        setStartValuesByTour(loaded);
      } else {
        setStartValuesByTour({});
      }
    });

    return () => unsubscribe();
  }, [FID, selectedYear]);

  // Startwert ändern + direkt in Firebase speichern
  const handleStartValueChange = (tourName, zeroBasedMonth, field, value) => {
    const parsed = parseFloat(value) || 0;
    setStartValuesByTour((prev) => {
      const newState = { ...prev };
      const oldArr = newState[tourName] || Array.from({ length: 12 }, () => ({ fp: 0, gp: 0 }));
      const updatedArr = [...oldArr];
      updatedArr[zeroBasedMonth] = {
        ...updatedArr[zeroBasedMonth],
        [field]: parsed,
      };
      newState[tourName] = updatedArr;
      return newState;
    });

    // Auch in Firebase
    if (FID) {
      const db = getDatabase();
      const pathRef = ref(db, `palettenkonto/${FID}/startwerte/${selectedYear}/${tourName}/${zeroBasedMonth}`);
      set(pathRef, {
        ...startValuesByTour[tourName]?.[zeroBasedMonth],
        [field]: parsed,
      });
    }
  };

  // Summenberechnung (unverändert)
  const computeTotals = (list) => {
    return list.reduce(
      (acc, curr) => {
        acc.totalTagessaldoFP += curr.totalTagessaldoFP;
        acc.totalTagessaldoGP += curr.totalTagessaldoGP;
        acc.totalLeergutAbholungFP += curr.totalLeergutAbholungFP;
        acc.totalLeergutAbholungGP += curr.totalLeergutAbholungGP;
        acc.diffFP += curr.diffFP;
        acc.diffGP += curr.diffGP;

        acc.totalUebernahmeFP += curr.totalUebernahmeFP || 0;
        acc.totalUebernahmeGP += curr.totalUebernahmeGP || 0;
        acc.totalLeergutrueckgabeFP += curr.totalLeergutrueckgabeFP || 0;
        acc.totalLeergutrueckgabeGP += curr.totalLeergutrueckgabeGP || 0;
        return acc;
      },
      {
        totalTagessaldoFP: 0,
        totalTagessaldoGP: 0,
        totalLeergutAbholungFP: 0,
        totalLeergutAbholungGP: 0,
        diffFP: 0,
        diffGP: 0,
        totalUebernahmeFP: 0,
        totalUebernahmeGP: 0,
        totalLeergutrueckgabeFP: 0,
        totalLeergutrueckgabeGP: 0,
      }
    );
  };

  // Table-Rendering mit React-Bootstrap
  const renderTable = (dataList, totals) => {
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name/Tour</th>
            <th>Tagessaldo FP</th>
            <th>Tagessaldo GP</th>
            <th>Leergutabholung FP</th>
            <th>Leergutabholung GP</th>
            <th>Übernahme FP</th>
            <th>Übernahme GP</th>
            <th>Leergutrückgabe FP</th>
            <th>Leergutrückgabe GP</th>
            <th>Differenz FP</th>
            <th>Differenz GP</th>
          </tr>
        </thead>
        <tbody>
          {dataList.map((entry) => {
            const {
              name,
              totalTagessaldoFP,
              totalTagessaldoGP,
              totalLeergutAbholungFP,
              totalLeergutAbholungGP,
              totalUebernahmeFP,
              totalUebernahmeGP,
              totalLeergutrueckgabeFP,
              totalLeergutrueckgabeGP,
              diffFP,
              diffGP,
            } = entry;

            // Mitarbeiter vs. Tour
            let displayName = name;
            if (currentMitarbeiter[name]) {
              displayName = currentMitarbeiter[name].name;
            }
            const diffFPColor = diffFP > 0 ? "red" : "green";
            const diffGPColor = diffGP > 0 ? "red" : "green";

            return (
              <tr key={name}>
                <td>{displayName}</td>
                <td>{totalTagessaldoFP}</td>
                <td>{totalTagessaldoGP}</td>
                <td>{totalLeergutAbholungFP}</td>
                <td>{totalLeergutAbholungGP}</td>
                <td>{totalUebernahmeFP}</td>
                <td>{totalUebernahmeGP}</td>
                <td>{totalLeergutrueckgabeFP}</td>
                <td>{totalLeergutrueckgabeGP}</td>
                <td style={{ fontWeight: "bold", color: diffFPColor }}>{diffFP}</td>
                <td style={{ fontWeight: "bold", color: diffGPColor }}>{diffGP}</td>
              </tr>
            );
          })}

          {dataList.length > 0 && (
            <tr style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}>
              <td>Gesamt</td>
              <td>{totals.totalTagessaldoFP}</td>
              <td>{totals.totalTagessaldoGP}</td>
              <td>{totals.totalLeergutAbholungFP}</td>
              <td>{totals.totalLeergutAbholungGP}</td>
              <td>{totals.totalUebernahmeFP}</td>
              <td>{totals.totalUebernahmeGP}</td>
              <td>{totals.totalLeergutrueckgabeFP}</td>
              <td>{totals.totalLeergutrueckgabeGP}</td>
              <td style={{ color: totals.diffFP > 0 ? "red" : "green" }}>
                {totals.diffFP}
              </td>
              <td style={{ color: totals.diffGP > 0 ? "red" : "green" }}>
                {totals.diffGP}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  if (isLoading) return <div>Lade Daten...</div>;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  // Summaries trennen in Mitarbeiter/Touren (optional)
  const userSummaries = summaries.filter((s) => currentMitarbeiter[s.name]);
  const tourSummaries = summaries.filter((s) => !currentMitarbeiter[s.name]);

  // ----------------------
  // RENDER
  // ----------------------
  const years = [];
  for (let y = currentYear - 5; y <= currentYear; y++) {
    years.push(y);
  }

  return (
    <Container>
      <Card className="mt-3 bg-light align-items-stretch myCard">
        <Card.Header className="bg-primary text-white py-3 d-flex justify-content-between">
          <h4 className="mb-0 text-center">Palettenkonto Übersicht</h4>
          <Button variant="dark" onClick={handleOpenModal}>
            Startwerte bearbeiten
          </Button>
        </Card.Header>

        <div className="p-3">
          <Form.Group controlId="yearSelect" className="mb-3">
            <Form.Label>Jahr wählen:</Form.Label>
            <Form.Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(Number(e.target.value))}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>

        <Tabs defaultActiveKey="mitarbeiter" className="mt-3">
          <Tab eventKey="mitarbeiter" title="Mitarbeiter">
            {renderTable(userSummaries, computeTotals(userSummaries))}
          </Tab>
          <Tab eventKey="touren" title="Touren">
            {renderTable(tourSummaries, computeTotals(tourSummaries))}
          </Tab>
          <Tab eventKey="monatlich" title="Monatlich">
  {/* 
    1) Umhüllendes <div>, das eine feste Höhe setzt
       => vertical scroll, wenn Tabelle zu groß wird
  */}
  <div style={{ maxHeight: "70vh", overflowY: "auto", padding: "1rem" }}>
    {monthlySummaries.map((monthData) => {
      const zeroIndex = monthData.month;
      const monthName = new Date(selectedYear, zeroIndex).toLocaleString("de-DE", {
        month: "long",
      });

      // Nur Touren bekommen Startwerte
      const monthlyAll = monthData.summaries.map((item) => {
        if (!currentMitarbeiter[item.user]) {
          // => Tour
          const sv = startValuesByTour[item.name]?.[zeroIndex] || { fp: 0, gp: 0 };
          return {
            ...item,
            totalTagessaldoFP: item.totalTagessaldoFP + sv.fp,
            totalTagessaldoGP: item.totalTagessaldoGP + sv.gp,
            diffFP: item.diffFP + sv.fp,
            diffGP: item.diffGP + sv.gp,
          };
        } else {
          // Mitarbeiter
          return item;
        }
      });

      if (monthlyAll.length === 0) return null;

      const totals = computeTotals(monthlyAll);

      return (
        <div key={zeroIndex} style={{ marginBottom: "2rem" }}>
          <h5>
            {monthName} {selectedYear}
          </h5>
          {/* 
            2) Table mit React-Bootstrap:
               => "responsive" sorgt für horizontales Scrollen
               wenn es zu breit wird
          */}
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Name / Tour</th>
                <th>Tagessaldo FP</th>
                <th>Tagessaldo GP</th>
                <th>Leergutabholung FP</th>
                <th>Leergutabholung GP</th>
                <th>Übernahme FP</th>
                <th>Übernahme GP</th>
                <th>Leergutrückgabe FP</th>
                <th>Leergutrückgabe GP</th>
                <th>Differenz FP</th>
                <th>Differenz GP</th>
              </tr>
            </thead>
            <tbody>
              {monthlyAll.map((entry) => {
                const {
                  name,
                  totalTagessaldoFP,
                  totalTagessaldoGP,
                  totalLeergutAbholungFP,
                  totalLeergutAbholungGP,
                  totalUebernahmeFP,
                  totalUebernahmeGP,
                  totalLeergutrueckgabeFP,
                  totalLeergutrueckgabeGP,
                  diffFP,
                  diffGP,
                } = entry;

                // Mitarbeiter vs. Tour
                let displayName = name;
                if (currentMitarbeiter[name]) {
                  displayName = currentMitarbeiter[name].name;
                }

                const diffFPColor = diffFP > 0 ? "red" : "green";
                const diffGPColor = diffGP > 0 ? "red" : "green";

                return (
                  <tr key={name}>
                    <td>{displayName}</td>
                    <td>{totalTagessaldoFP}</td>
                    <td>{totalTagessaldoGP}</td>
                    <td>{totalLeergutAbholungFP}</td>
                    <td>{totalLeergutAbholungGP}</td>
                    <td>{totalUebernahmeFP}</td>
                    <td>{totalUebernahmeGP}</td>
                    <td>{totalLeergutrueckgabeFP}</td>
                    <td>{totalLeergutrueckgabeGP}</td>
                    <td style={{ fontWeight: "bold", color: diffFPColor }}>{diffFP}</td>
                    <td style={{ fontWeight: "bold", color: diffGPColor }}>{diffGP}</td>
                  </tr>
                );
              })}
              {/* Gesamt-Zeile */}
              {monthlyAll.length > 0 && (
                <tr style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}>
                  <td>Gesamt</td>
                  <td>{totals.totalTagessaldoFP}</td>
                  <td>{totals.totalTagessaldoGP}</td>
                  <td>{totals.totalLeergutAbholungFP}</td>
                  <td>{totals.totalLeergutAbholungGP}</td>
                  <td>{totals.totalUebernahmeFP}</td>
                  <td>{totals.totalUebernahmeGP}</td>
                  <td>{totals.totalLeergutrueckgabeFP}</td>
                  <td>{totals.totalLeergutrueckgabeGP}</td>
                  <td style={{ color: totals.diffFP > 0 ? "red" : "green" }}>
                    {totals.diffFP}
                  </td>
                  <td style={{ color: totals.diffGP > 0 ? "red" : "green" }}>
                    {totals.diffGP}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      );
    })}
  </div>
</Tab>

        </Tabs>
      </Card>

      {/* ---------------------------
         MODAL: Anfangswerte (FP/GP) für ALLE Touren und
                für EINEN gewählten Monat (1..12)
         --------------------------- */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Startwerte Bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* 1) Monat wählen, 1-basiert */}
          <Form.Group className="mb-3">
            <Form.Label>Monat wählen (1 = Januar ... 12 = Dezember)</Form.Label>
            <Form.Control
              type="number"
              min={1}
              max={12}
              value={modalMonth}
              onChange={(e) => setModalMonth(Math.max(1, Math.min(12, Number(e.target.value))))}
            />
          </Form.Group>

          {/* 2) Tabelle oder Liste der Touren => für jede Tour: FP/GP angeben */}
          <p>Touren-Startwerte für diesen Monat eingeben:</p>
          {tourSummaries.length === 0 ? (
            <div>Keine Touren-Daten vorhanden.</div>
          ) : (
            tourSummaries.map((tourItem) => {
              const tourName = tourItem.name;
              const zeroIndex = modalMonth - 1; // 0-basiert
              const sv = startValuesByTour[tourName]?.[zeroIndex] || { fp: 0, gp: 0 };

              return (
                <div
                  key={tourName}
                  style={{ marginBottom: "1rem", borderBottom: "1px solid #ccc" }}
                >
                  <h6>Tour: {tourName}</h6>
                  <Form.Group className="mb-2">
                    <Form.Label>FP Startwert</Form.Label>
                    <Form.Control
                      type="number"
                      value={sv.fp}
                      onChange={(e) =>
                        handleStartValueChange(tourName, zeroIndex, "fp", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>GP Startwert</Form.Label>
                    <Form.Control
                      type="number"
                      value={sv.gp}
                      onChange={(e) =>
                        handleStartValueChange(tourName, zeroIndex, "gp", e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
              );
            })
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Fertig
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PalettenKonto;
